<!-- 确认收货成功 -->
<template>
  <div>
    <Header/>
    <div class="ConfirmTheGoods">
      <div class="confirmTheGoodsContent">
        <p>{{'确认收货成功' | formatTrans}}</p>
        <div class="btns">
          <el-button
            @click="goHome"
            style="width:14%;"
            type="primary"
            round
            class="button"
          >{{'返回主页' | formatTrans}}</el-button>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
export default {
  name: "ConfirmTheGoods",
  data() {
    return {};
  },
  methods: {
    goHome() {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.replace("/");
      }
    }
  }
};
</script>
<style <style lang="scss" scoped>
.ConfirmTheGoods {
  padding: 100px 0;
  // margin-top: 32px;
  .confirmTheGoodsContent {
    margin-top: 200px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 40px;
  }
  .btns {
    margin-top: 100px;
    .button {
      color: #fff;
      background-color: #b98465;
      border-color: #b98465;
      font-size: 16px !important;
      margin-top: 28px;
      margin-bottom: 48px;
    }
  }
}
</style>



