<template>
  <div class="SellerAgreeRefProg">
    <Header/>
    <div class="top-space-line"></div>
    <div class="rsh-wrap">
      <div class="left-wrap">
        <div class="step-t-f">
          <div class="left">
            <span class="el-icon-success"></span>
            <span class="step-t-word">{{ 'Buyer Requests A Refund Only' | formatTrans }}</span>
          </div>
          <div class="right">
            <span>2024-07-22 08:10:25</span>
          </div>
        </div>
        <div class="order-remind">
          <span>{{ 'If you encounter any problems, please contact the seller.' | formatTrans}}</span>
        </div>
        <div class="order-info">
          <div class="fir-line">
            <span>{{ 'Service Type: ' | formatTrans }}{{ 'Refund only' | formatTrans }}</span>
          </div>
          <div class="sec-line">
            <span>{{ 'Reason For Refund: The seller sent the wrong item' | formatTrans }}</span>
          </div>
          <div class="third-line">
            <span>{{ 'Refund Amount: ' }}{{ curSymbol }}41.50</span>
          </div>
        </div>

        <div class="step-sec">
          <div class="step-t-s">
            <div class="left">
              <span class="el-icon-success"></span>
              <span class="step-t-word">{{ 'Seller Processing Refund Request' | formatTrans }}</span>
            </div>
            <div class="right">
              <span>2024-07-22 08:10:25</span>
            </div>
          </div>
          <div class="re-word-sts">{{ 'Please be patient and wait for processing.' | formatTrans }}</div>
          <div class="request-info">
            <span>{{ 'The seller has agreed to your refund request.' | formatTrans }}</span>
          </div>
        </div>

        <div class="step-third">
          <div class="step-t-t">
            <span class="proc-third">3</span>
            <span class="proc-third-word">{{ 'Refund Completed' | formatTrans }}</span>
          </div>
          <div class="re-word-sts">Return to the original route.</div>
        </div>
        <div class="progress-info">
          <div class="fir-line">
            <span>{{ 'Refund In Progress ' | formatTrans }}</span>
          </div>
          <div class="sec-line">
            <span>{{ 'Refund Amount: ' | formatTrans }}{{ curSymbol }}41.50</span>
          </div>
          <div class="third-line">
            <span>{{ 'Refund Method: Return to the original route' }}{{ curSymbol }}41.50</span>
          </div>
        </div>
      </div>

      <div class="orderOverview">
        <div class="texc">{{'订单摘要' | formatTrans}}</div>
        <div class="sin-goods-wrap">
          <div class="goods-img">
            <img
              src="http://192.168.1.3:9001/b2c/ua/2024/8/6/998d31e1-3405-df32-23d5-6038093aa9a2Keiskei_Arco Floor Lamp_@1x.png"
            >
          </div>
          <div class="goods-info-wrap">
            <div class="goods-name">{{ '阿萨德困哪是的呢' }}</div>

            <div class="sec-line">
              <div class="goods-price">{{ curSymbol }}{{ 134 }}</div>
              <div class="goods-num">{{'数量' | formatTrans}}: {{ 12 }}</div>
            </div>

            <div class="third-line">
              <span class="sin-guige-wrap">
                <span>Colour:</span>
                <el-tooltip content="绿色" placement="top">
                  <span class="color-img">
                    <img
                      src="http://192.168.1.3:9001/b2c/ua/2024/8/6/998d31e1-3405-df32-23d5-6038093aa9a2Keiskei_Arco Floor Lamp_@1x.png"
                    >
                  </span>
                </el-tooltip>
              </span>
              <span class="sin-guige-wrap">尺寸: 0.6m</span>
            </div>
          </div>
        </div>

        <div class="total-sta">
          <div class="item-tot">
            <div>{{'Amount' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ 3339 }}</div>
          </div>

          <div class="item-tot">
            <div>{{'运费' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ 0 }}</div>
          </div>

          <div class="item-tot">
            <div>{{'Tax' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ 0 }}</div>
          </div>

          <div class="item-tot">
            <div>{{'合计' | formatTrans}}:</div>
            <div class="sub-price">{{ curSymbol }}{{ 3339 }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bot-space-line"></div>
    <Footer/>
  </div>
</template>
<script>
import { globalVari } from "@/utils/variable";
import { appCreateOrders, logout } from "@/api/index";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import { getTrans } from "@/api/set.js";

export default {
  name: "SellerAgreeRefProg",
  components: {
    Header,
    Footer
  },

  data() {
    return {};
  },

  created() {
    try {
      this.curSymbol = sessionStorage.getItem("currencySymbol");
    } catch (error) {}
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },

  methods: {}
};
</script>
<style lang="scss" scoped>
.SellerAgreeRefProg {
  .top-space-line {
    height: 80px;
  }

  .bot-space-line {
    height: 100px;
  }

  .rsh-wrap {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;

    .left-wrap {
      margin-top: 50px;
      width: 870px;

      .step-t-f {
        color: rgba(0, 0, 0, 0.85);
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .step-t-word {
          margin-left: 12px;
        }

        .el-icon-success {
          font-size: 26px;
        }

        .right {
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
          opacity: 0.65;
        }
      }

      .order-remind {
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
        margin-top: 8px;
        margin-left: 38px;
      }

      .order-info {
        margin-top: 24px;
        margin-left: 38px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;

        .sec-line,
        .third-line {
          margin-top: 6px;
        }
      }

      .step-sec {
        border-radius: 10px;
        margin-top: 48px;

        .step-t-s {
          color: rgba(0, 0, 0, 0.85);
          font-size: 24px;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .step-t-word {
            margin-left: 12px;
          }

          .el-icon-success {
            font-size: 26px;
          }

          .right {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            opacity: 0.65;
          }
        }

        .re-word-sts {
          margin-left: 40px;
          margin-top: 6px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 12px;
        }

        .request-info {
          border-radius: 8px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          margin-left: 40px;
          margin-top: 24px;

          .spec-rem {
            color: #b98465;
          }
        }
      }

      .step-third {
        border-radius: 10px;
        margin-top: 48px;

        .step-t-t {
          font-size: 24px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          align-items: center;

          .proc-third {
            width: 26px;
            height: 26px;
            border: 2px solid rgba(0, 0, 0, 0.85);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-size: 14px;
            box-sizing: border-box;
          }

          .proc-third-word {
            margin-left: 12px;
          }
        }

        .re-word-sts {
          color: rgba(0, 0, 0, 0.85);
          font-size: 12px;
          margin-top: 4px;
          margin-left: 40px;
        }
      }

      .progress-info {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin-top: 28px;
        padding: 16px 24px;

        .fir-line {
          color: rgba(0, 0, 0, 0.85);
          font-size: 20px;
          font-weight: bold;
        }

        .sec-line,
        .third-line {
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
        }

        .sec-line {
          margin-top: 12px;
        }

        .third-line {
          margin-top: 6px;
        }
      }
    }

    .orderOverview {
      width: 300px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      margin-top: 54px;

      .texc {
        margin-top: 24px;
      }

      .sin-goods-wrap {
        width: 252px;
        margin: 0 auto;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding: 24px 0 20px 0;
        display: flex;

        .goods-img {
          width: 70px;
          height: 70px;
          background: rgba(243, 243, 245, 1);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 80%;
          }
        }

        .goods-info-wrap {
          width: 170px;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .goods-name {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: 550;
          }

          .sec-line {
            margin-top: 4px;
            display: flex;
            justify-content: space-between;

            .goods-price {
              color: rgba(127, 73, 42, 1);
              font-size: 14px;
            }

            .goods-num {
              color: rgba(0, 0, 0, 0.85);
              font-size: 12px;
            }
          }

          .third-line {
            display: flex;
            justify-content: space-between;
            font-size: 12px;

            .sin-guige-wrap {
              display: flex;
              align-items: center;

              .color-img {
                width: 33px;
                height: 20px;
                background: rgba(243, 243, 245, 1);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 4px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .total-sta {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        padding: 20px 0 20px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        width: 252px;
        margin: 0 auto;

        .item-tot {
          margin-top: 12px;
          display: flex;
          justify-content: space-between;
        }

        .item-tot:nth-child(1) {
          margin-top: 0;
        }

        .sub-price {
          color: rgba(127, 73, 42, 1);
          font-weight: bold;
        }
      }
    }
  }
}
</style>
    
    