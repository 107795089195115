import VueResource from "vue-resource";
Vue.use(VueResource);
import Vue from "vue";
import VueRouter from "vue-router";

// 引入你的组件
import RegisterSuccess from "../components/Login/RegisterSuccess.vue";
import ResetPassword from "../components/Login/ResetPassword.vue";
import ForPasEmail from "../components/Login/ForPasEmail.vue";
import MyAccount from "../components/Login/MyAccount.vue";
import ChangePassword from "../components/Login/ChangePassword.vue";
import ActiveSuccess from "../components/Login/ActiveSuccess.vue";
import SearchResult from "../components/Search/SearchResult.vue";
import AddressBook from "../components/AddressBook/AddressBook.vue";
import AddAddress from "../components/AddressBook/AddAddress.vue";
import productsDesc from "../components/Products/productsDesc.vue";
import ViewCart from "../components/Products/ViewCart.vue";
import ShippingAddress from "../components/Products/ShippingAddress.vue";
import Payment from "../components/Products/Payment.vue";
import OrderHistory from "../components/OrderHistory/OrderHistory.vue";
import OrderStatus from "../components/OrderHistory/OrderStatus.vue";
import ContactUs from "../components/OrderHistory/ContactUs.vue";
import OrderDetails from "../components/PendingShipment/OrderDetails.vue";
import WfRApplyRef from "../components/PendingShipment/WfRApplyRef.vue";
import PaymentSuc from "../components/OrderHistory/PaymentSuc.vue";
import WishList from "../components/Classification/WishList.vue";
import AllClassification from "../components/Classification/AllClassification.vue";
import ClassificationDescribe from "../components/Classification/ClassificationDescribe.vue";
import BrandDescribe from "../components/Classification/BrandDescribe.vue";
import BrowseBrand from "../components/Classification/BrowseBrand.vue";
import SelectedSeries from "../components/Classification/SelectedSeries.vue";
import SelSerDescribe from "../components/Classification/SelSerDescribe.vue";

import HasGetGoDetail from "../components/HGSupRefund/HasGetGoDetail.vue";
import SupRTSelect from "../components/HGSupRefund/SupRTSelect.vue";
import RefundBuyerSup from "../components/HGSupRefund/RefundBuyerSup.vue";
import RefundSellerHan from "../components/HGSupRefund/RefundSellerHan.vue";
import SellerAgreeRefProg from "../components/HGSupRefund/SellerAgreeRefProg.vue";
import SellerAgreeRefSuc from "../components/HGSupRefund/SellerAgreeRefSuc.vue";
import SellerDisAgreeRef from "../components/HGSupRefund/SellerDisAgreeRef.vue";

import RefundOnly from "../components/PendingShipment/RefundOnly.vue";
import RefundOnlyProcessing from "../components/PendingShipment/RefundOnlyProcessing.vue";
import ConfirmTheGoods from "../components/PendingShipment/ConfirmTheGoods.vue";
import RefundOnlySuccess from "../components/PendingShipment/RefundOnlySuccess.vue";
import RefundOnlyFail from "../components/PendingShipment/RefundOnlyFail.vue";

import Login from "../Login.vue";
import Home from "../Home.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/Login",
      name: "Login",
      component: Login
    }, // 登录页
    {
      path: "/",
      name: "Home",
      component: Home
    }, // 默认就跳转此页面

    {
      path: "/RegisterSuccess",
      name: "RegisterSuccess",
      component: RegisterSuccess
    }, // 注册成功
    {
      path: "/ResetPassword",
      name: "ResetPassword",
      component: ResetPassword
    }, // 忘记密码
    {
      path: "/ForPasEmail",
      name: "ForPasEmail",
      component: ForPasEmail
    }, // 验证邮箱
    {
      path: "/ChangePassword",
      name: "ChangePassword",
      component: ChangePassword
    }, // 修改密码
    {
      path: "/MyAccount",
      name: "MyAccount",
      component: MyAccount
    }, // 忘记密码
    {
      path: "/SearchResult",
      name: "SearchResult",
      component: SearchResult
    }, // 搜索结果
    {
      path: "/AddressBook",
      name: "AddressBook",
      component: AddressBook
    }, // 地址簿
    {
      path: "/AddAddress",
      name: "AddAddress",
      component: AddAddress
    }, // 新增地址
    {
      path: "/productsDesc",
      name: "productsDesc",
      component: productsDesc
    }, // 商品详情
    {
      path: "/ViewCart",
      name: "ViewCart",
      component: ViewCart
    }, // 购物车列表
    {
      path: "/ShippingAddress",
      name: "ShippingAddress",
      component: ShippingAddress
    }, // 结算选择地址
    {
      path: "/Payment",
      name: "Payment",
      component: Payment
    }, // 结算选择地址
    {
      path: "/OrderHistory",
      name: "OrderHistory",
      component: OrderHistory
    }, // 历史订单
    {
      path: "/PaymentSuc",
      name: "PaymentSuc",
      component: PaymentSuc
    }, // 支付成功
    {
      path: "/OrderStatus",
      name: "OrderStatus",
      component: OrderStatus
    }, // 订单状态
    {
      path: "/ContactUs",
      name: "ContactUs",
      component: ContactUs
    }, // 联系我们
    {
      path: "/OrderDetails",
      name: "OrderDetails",
      component: OrderDetails
    }, // 待收货详情
    {
      path: "/WfRApplyRef",
      name: "WfRApplyRef",
      component: WfRApplyRef
    }, // 待收货申请退款
    {
      path: "/WishList",
      name: "WishList",
      component: WishList
    }, // 心愿清单
    {
      path: "/AllClassification",
      name: "AllClassification",
      component: AllClassification
    }, // 全部分类
    {
      path: "/ClassificationDescribe",
      name: "ClassificationDescribe",
      component: ClassificationDescribe
    }, // 详情分类
    {
      path: "/BrandDescribe",
      name: "BrandDescribe",
      component: BrandDescribe
    }, // 品牌
    {
      path: "/SelSerDescribe",
      name: "SelSerDescribe",
      component: SelSerDescribe
    }, // 精选
    {
      path: "/BrowseBrand",
      name: "BrowseBrand",
      component: BrowseBrand
    }, // 品牌店铺
    {
      path: "/SelectedSeries",
      name: "SelectedSeries",
      component: SelectedSeries
    }, // 精选系系
    {
      path: "/ActiveSuccess",
      name: "ActiveSuccess",
      component: ActiveSuccess
    }, // ActiveSuccess
    {
      path: "/HasGetGoDetail",
      name: "HasGetGoDetail",
      component: HasGetGoDetail
    }, // 已收货详情
    {
      path: "/SupRTSelect",
      name: "SupRTSelect",
      component: SupRTSelect
    }, // 已收货-申请退款类型选择
    {
      path: "/RefundBuyerSup",
      name: "RefundBuyerSup",
      component: RefundBuyerSup
    }, // 已收货-仅退款买家申请
    {
      path: "/RefundSellerHan",
      name: "RefundSellerHan",
      component: RefundSellerHan
    }, // 已收货-仅退款待卖家处理
    {
      path: "/SellerAgreeRefProg",
      name: "SellerAgreeRefProg",
      component: SellerAgreeRefProg
    }, // 已收货-仅退款卖家已同意退款，退款中
    {
      path: "/SellerAgreeRefSuc",
      name: "SellerAgreeRefSuc",
      component: SellerAgreeRefSuc
    }, // 已收货-仅退款退款成功
    {
      path: "/SellerDisAgreeRef",
      name: "SellerDisAgreeRef",
      component: SellerDisAgreeRef
    }, // 已收货-仅退款卖家拒绝退款申请 // 已收货-仅退款待买家处理
    {
      path: "/RefundOnly",
      name: "RefundOnly",
      component: RefundOnly // 申请仅退款
    },
    {
      path: "/RefundOnlyProcessing",
      name: "RefundOnlyProcessing",
      component: RefundOnlyProcessing // 申请仅退款--卖家处理中
    },
    {
      path: "/ConfirmTheGoods",
      name: "ConfirmTheGoods",
      component: ConfirmTheGoods // 确认收货成功
    },
    {
      path: "/RefundOnlySuccess",
      name: "RefundOnlySuccess",
      component: RefundOnlySuccess // 未发货仅退款成功
    },
    {
      path: "/RefundOnlyFail",
      name: "RefundOnlyFail",
      component: RefundOnlyFail // 未发货仅退款失败
    }
  ]
});

const PATH_FILTER = [
  "/",
  "/ForPasEmail",
  "/BrowseBrand",
  "/SelectedSeries",
  "/SearchResult",
  "/ClassificationDescribe",
  "/BrandDescribe",
  "/SelSerDescribe",
  "/productsDesc",
  "/ContactUs"
];
router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  if (PATH_FILTER.indexOf(to.path) > -1) {
    next();
  } else {
    if (to.path !== "/Login" && !token) {
      next("/Login");
    } else {
      next();
    }
  }
});

export default router;
