<!-- 仅退款--卖家拒绝 -->
<template>
  <div class="RefundOnlyProcessing">
    <Header/>
    <div class="paym-top-space-line"></div>
    <div class="paym-wrap">
      <div class="left-wrap">
        <div class="flexSty">
          <div class="step-t-f fl">
            <span class="el-icon-success"></span>
            <span class="step-t-word">{{ '买方仅要求退款' | formatTrans }}</span>
          </div>
          <div class="fr">{{this.returnAddTime}}</div>
        </div>
        <div class="re-word-stf">{{'如果您遇到任何问题，请联系卖家' | formatTrans}}</div>
        <div class="clearb"></div>
        <div>
          <div class="clearb"></div>
          <p class="refundTitle marT20">{{'服务类型' | formatTrans}}：{{'仅退款' | formatTrans}}</p>
          <p
            class="refundTitle"
          >{{'退款金额' | formatTrans}}：{{ curSymbol }}{{this.refundOnlyRowDatasSubmitT.orderGoodsAmount}}</p>
        </div>

        <div class="stepN">
          <div class="step-t-s">
            <span class="el-icon-error"></span>
            <span class="proc-sec-word">{{ '卖家正在处理退款请求' | formatTrans }}</span>
          </div>
          <div class="re-word-sts">请耐心等待处理</div>
        </div>
        <div class="refundTitle marT20">{{'卖家已拒绝您的退款请求' | formatTrans}}</div>
        <div class="refundTitle">{{'拒绝原因' | formatTrans}}：{{this.returnShopMessage}}</div>
        <div class="refundTitle">
          <a @click="ReapplyBtn">{{'重新申请' | formatTrans}}</a>
        </div>
        <div class="refundTitle">
          <a @click="ContactUsFn">{{'联系卖家' | formatTrans}}</a>
        </div>
        <div class="stepNBg">
          <div class="step-t-sec">
            <span class="proc-sec">3</span>
            <span class="proc-sec-word">{{'退款已完成' | formatTrans}}</span>
          </div>
          <div class="re-word-sts">{{'返回原路线' | formatTrans}}</div>
        </div>
      </div>

      <div class="orderOverview">
        <div class="texc">{{'订单摘要' | formatTrans}}</div>
        <div
          v-for="(item,index) in refundOnlyRowDatasSubmitT.soldOrderByGoodsDetailsList"
          :key="index"
          class="sin-goods-wrap"
        >
          <div class="goods-img">
            <img :src="item.goodsImage">
          </div>
          <div class="goods-info-wrap">
            <div class="goods-name">{{ item.goodsName }}</div>

            <div class="sec-line">
              <div class="goods-price">{{ curSymbol }}{{ item.goodsPrice }}</div>
              <div class="goods-num">{{'数量' | formatTrans}}: {{item.orderGoodsNum}}</div>
            </div>

            <div class="third-line">
              <span
                v-for="(itemF,indexF) in item.specification"
                :key="indexF"
                class="sin-guige-wrap"
              >
                <template v-if="itemF.paramName == 'Colour'">
                  <span>{{ itemF.paramName }}:</span>
                  <el-tooltip :content="itemF.paramValue" placement="top">
                    <span class="color-img">
                      <img :src="itemF.paramImage">
                    </span>
                  </el-tooltip>
                </template>
                <template v-else>{{ itemF.paramName }}: {{ itemF.paramValue }}</template>
              </span>
            </div>
          </div>
        </div>

        <div class="total-sta">
          <div class="item-tot">
            <div>{{'金额' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ goodsTotPri }}</div>
          </div>

          <div class="item-tot">
            <div>{{'运费' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ freiVal }}</div>
          </div>

          <div class="item-tot">
            <div>{{'Tax' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ taxVal }}</div>
          </div>

          <div class="item-tot">
            <div>{{'合计' | formatTrans}}</div>
            <div class="sub-price">{{ curSymbol }}{{ subVal }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="paym-bot-space-line"></div>
    <el-dialog title="温馨提示" :visible.sync="revokeDialogVisible" width="30%" icon="el-icon-message">
      <span slot="title" class="dialog-title" style>
        <i class="el-icon-warning" style="color:#e6a23c"></i>
        温馨提示
        <!-- 这里是图标 -->
      </span>
      <span>您仅能主动撤销一次，撤销后将无法再次主动撤销，且超过售后保障期后不可再次发起售后,是否继续?</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelbtn" round @click="revokeDialogVisible = false">取 消</el-button>
        <el-button class="cancelbtnBg" round @click="revokeSumbit()">确 定</el-button>
      </span>
    </el-dialog>
    <Footer/>
  </div>
</template>
<script>
import { globalVari } from "@/utils/variable";
import {
  appCreateOrders,
  logout,
  revokeReturn,
  selectOrderReturn
} from "@/api/index";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import { getTrans } from "@/api/set.js";

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      revokeDialogVisible: false,
      refundOnlyRowDatasSubmitT: {},
      appCreateOrdersDatas: [],
      paymentDialog: false, // 支付对话框
      returnCode: "",
      returnState: "",
      returnAddTime: "",
      returnShopMessage: "", // 卖家拒绝原因
      revokeReturnBtnShow: true,
      goodsIds: [],
      goodsBaseList: [],
      active: 0,
      radio: "1",
      addNew: "",
      formInline: {},
      addForm: {},
      addNewAddressForm: {},
      rules: {},
      goodsOrderList: [],
      addrListsdatas: "",
      goodsTotPri: 0,
      freiVal: 0,
      taxVal: 0,
      subVal: 0,
      curSymbol: "",
      payWord: getTrans(
        "To continue your order with PayPal, please select 'CONFlRM DETAlLs'below. Then select 'Pay with PayPal'. You wil then be prompted to login toyour PayPal account. Once the transaction is complete you wil be directed back to the BAB!TINO site for your order confrmation"
      )
    };
  },

  created() {
    try {
      this.refundOnlyRowDatasSubmitT = JSON.parse(
        sessionStorage.getItem("refundOnlyRowData")
      );
      console.log(
        "this.refundOnlyRowDatasSubmitT====>",
        this.refundOnlyRowDatasSubmitT
      );
      this.curSymbol = sessionStorage.getItem("currencySymbol");
      this.goodsOrderList = JSON.parse(
        sessionStorage.getItem("goodsListsdatas")
      );
      this.addrListsdatas = JSON.parse(
        sessionStorage.getItem("addressListsdatas")
      );

      this.getTotalData();
    } catch (error) {}
    this.selectOrderReturnFn();
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },

  methods: {
    // 重新申请
    ReapplyBtn() {
      this.$router.push("/RefundOnly");
    },
    // 联系卖家
    ContactUsFn() {
      this.$router.push("/ContactUs");
    },
    getTotalData() {
      let totPri = 0;
      for (
        let i = 0;
        i < this.refundOnlyRowDatasSubmitT.soldOrderByGoodsDetailsList.length;
        i++
      ) {
        totPri +=
          this.refundOnlyRowDatasSubmitT.soldOrderByGoodsDetailsList[i]
            .goodsPrice *
          this.refundOnlyRowDatasSubmitT.soldOrderByGoodsDetailsList[i]
            .orderGoodsNum;
      }

      this.goodsTotPri = totPri;
      this.subVal = totPri;
    },
    selectOrderReturnFn() {
      let obj = {
        token: sessionStorage.getItem("token"),
        orderId: this.refundOnlyRowDatasSubmitT.orderId
      };
      selectOrderReturn(obj).then(res => {
        if (res.code == 1) {
          this.returnCode = res.data.returnCode;
          this.returnState = res.data.returnState;
          this.returnAddTime = res.data.returnAddTime;
          this.returnShopMessage = res.data.returnShopMessage;
        }
      });
    },
    // 撤销
    revokeBtn() {
      // this.revokeData = val;
      this.selectOrderReturnFn();
      this.revokeDialogVisible = true;
    },
    // 撤消退款申请
    revokeSumbit() {
      let obj = {
        token: sessionStorage.getItem("token"),
        orderId: this.refundOnlyRowDatasSubmitT.orderId,
        returnCode: this.returnCode
      };
      revokeReturn(obj).then(res => {
        if (res.code == 1) {
          this.$message({
            message: getTrans("撤消成功！"),
            type: "success",
            duration: 3000,
            customClass: "auto-mes-box"
          });
          this.revokeReturnBtnShow = false;
          this.selectOrderReturnFn();
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
        this.revokeDialogVisible = false;
        // this.enquireSoldOrdersFn();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.RefundOnlyProcessing {
  .paym-top-space-line {
    height: 80px;
  }

  .paym-bot-space-line {
    height: 100px;
  }
  .cancelbtn {
    color: #b98465;
    background-color: #fff;
    border-color: #b98465;
    margin-top: 20px;
    padding: 10px 30px;
    font-size: 16px;
  }
  .cancelbtn:hover {
    color: #fff;
    background-color: #b98465;
    border-color: #b98465;
    margin-top: 20px;
    padding: 10px 30px;
    font-size: 16px;
  }
  .cancelbtnBg {
    color: #fff;
    background-color: #b98465;
    border-color: #b98465;
    margin-top: 20px;
    padding: 10px 30px;
    font-size: 16px;
  }
  .cancelbtnBg:hover {
    color: #b98465;
    background-color: #fff;
    border-color: #b98465;
    margin-top: 20px;
    padding: 10px 30px;
    font-size: 16px;
  }
  .paym-wrap {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;

    .left-wrap {
      margin-top: 50px;
      width: 870px;
      .flexSty {
        display: flex;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
      .step-t-f {
        color: rgba(0, 0, 0, 0.85);
        font-size: 24px;
        font-weight: bold;

        .step-t-word {
          margin-left: 12px;
        }
      }
      .re-word-stf {
        margin-left: 40px;
        margin-top: 4px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
      }
      .refundTitle {
        margin-left: 40px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        line-height: 28px;
      }
      .undo {
        margin-left: 40px;
        span {
          color: #2d74ff;
          font-size: 14px;
        }
      }
      .user-info {
        margin-top: 24px;
        margin-left: 38px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;

        .sec-line {
          margin-top: 5px;
        }
      }

      .stepN {
        border-radius: 10px;
        margin-top: 48px;

        .step-t-s {
          font-size: 26px;
          font-weight: bold;

          .proc-sec {
            width: 26px;
            height: 26px;
            border: 2px solid rgba(0, 0, 0, 0.85);
            display: inline-block;
            text-align: center;
            line-height: 26px;
            border-radius: 50%;
          }

          .proc-sec-word {
            margin-left: 12px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 24px;
          }
        }
        .step-t-sec {
          font-size: 26px;
          font-weight: bold;
          opacity: 0.45;

          .proc-sec {
            width: 26px;
            height: 26px;
            border: 2px solid rgba(0, 0, 0, 0.85);
            display: inline-block;
            text-align: center;
            line-height: 26px;
            border-radius: 50%;
          }

          .proc-sec-word {
            margin-left: 12px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 24px;
          }
        }
        .re-word-sts {
          margin-left: 40px;
          margin-top: 4px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 12px;
        }

        .pay-el-tab {
          margin-top: 32px;
        }
      }
      .stepNBg {
        border-radius: 10px;
        padding: 20px 24px;
        margin-top: 48px;
        background: rgba(243, 243, 245, 1);

        .step-t-sec {
          font-size: 26px;
          font-weight: bold;
          opacity: 0.45;

          .proc-sec {
            width: 26px;
            height: 26px;
            border: 2px solid rgba(0, 0, 0, 0.85);
            display: inline-block;
            text-align: center;
            line-height: 26px;
            border-radius: 50%;
          }

          .proc-sec-word {
            margin-left: 12px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 24px;
          }
        }

        .re-word-sts {
          margin-left: 40px;
          margin-top: 4px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 12px;
          opacity: 0.45;
        }
      }
    }
    .refundOnlytips {
      width: 870px;
      height: 56px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      line-height: 56px;
      padding-left: 20px;
      margin-top: 20px;
    }
    .orderOverview {
      width: 300px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      margin-top: 54px;

      .texc {
        margin-top: 24px;
        margin-bottom: 24px;
      }

      .sin-goods-wrap {
        width: 252px;
        margin: 0 auto;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding: 24px 0 20px 0;
        display: flex;

        .goods-img {
          width: 70px;
          height: 70px;
          background: rgba(243, 243, 245, 1);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 80%;
          }
        }

        .goods-info-wrap {
          width: 170px;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .goods-name {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: 550;
          }

          .sec-line {
            margin-top: 4px;
            display: flex;
            justify-content: space-between;

            .goods-price {
              color: rgba(127, 73, 42, 1);
              font-size: 14px;
            }

            .goods-num {
              color: rgba(0, 0, 0, 0.85);
              font-size: 12px;
            }
          }

          .third-line {
            display: flex;
            justify-content: space-between;
            font-size: 12px;

            .sin-guige-wrap {
              display: flex;
              align-items: center;

              .color-img {
                width: 33px;
                height: 20px;
                background: rgba(243, 243, 245, 1);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 4px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .total-sta {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        padding: 20px 0 20px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        width: 252px;
        margin: 0 auto;

        .item-tot {
          margin-top: 12px;
          display: flex;
          justify-content: space-between;
        }

        .item-tot:nth-child(1) {
          margin-top: 0;
        }

        .sub-price {
          color: rgba(127, 73, 42, 1);
          font-weight: bold;
        }
      }
    }
  }

  .btns {
    .button {
      color: #fff;
      background-color: #b98465;
      border-color: #b98465;
      font-size: 18px !important;
      margin-top: 20px;
    }
    .shopNow {
      color: #b98465;
      background-color: #fff;
      border-color: #b98465;
      font-size: 18px !important;
      margin-top: 20px;
    }
  }

  .sizeS {
    li:first-child {
      margin-left: 0;
    }
  }

  .stepS {
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    div:first-child {
      font-size: 20px;
      font-weight: bold;
      margin: 20px 0;
    }
    div:last-child {
      // font-size: 20px;
      // font-weight: bold;
      margin: 10px 0;
    }
  }

  ::v-deep .el-form-item__label {
    line-height: 60px;
  }
}
</style>

