<template>
  <div class="HasGetGoDetail">
    <Header/>
    <div class="order-re-con">
      <div class="titles">
        <div class="title-point"></div>
        <div class="titleBig">{{'订单状态' | formatTrans}}</div>
      </div>

      <div class="order-status">
        <el-tag v-show="ordersDetials.orderStatus =='1'" type="warning">{{'待付款'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='3'" type="warning">{{'待发货'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='4'" type="warning">{{'已发货'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='6'" type="warning">{{'确认收货'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='7'" type="warning">{{'取消'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='8'" type="warning">{{'退款中'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='9'" type="warning">{{'已退款'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='11'" type="warning">{{'待自提'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='12'" type="warning">{{'虚拟订单已过期'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='16'" type="warning">{{'部分发货'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='22'" type="warning">{{'未发货退款中'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='23'" type="warning">{{'收货退款中'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='24'" type="warning">{{'收货退货中'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='25'" type="warning">{{'退货完成'| formatTrans}}</el-tag>
        <el-tag v-show="ordersDetials.orderStatus =='26'" type="warning">{{'继续发货'| formatTrans}}</el-tag>
      </div>

      <div class="titles titles-order">
        <div class="title-point"></div>
        <div class="titleBig">{{'订单信息' | formatTrans}}</div>
      </div>

      <div class="order-info order-info-fir">
        <div class="first-line">{{'邮寄地址' | formatTrans}}</div>
        <div
          class="sec-line"
        >{{ ordersDetials.orderReceiverName | formatTrans}}, {{ ordersDetials.orderReceiverContact | formatTrans}}</div>
        <div class="third-line">{{ ordersDetials.orderReceiverAddress | formatTrans}}</div>
      </div>

      <div class="order-info">
        <div class="first-line">{{'卖方信息' | formatTrans}}</div>
        <div class="sec-line contact-us">
          <span @click="contactUsFn">{{ '联系我们' | formatTrans}}</span>
        </div>
      </div>

      <div class="order-info-third">
        <div class="order-table-wrap">
          <div class="order-table-m">
            <div class="table-title">
              <div class="clearb"></div>
              <div class="Order-Content">{{'订单内容' | formatTrans}}</div>

              <div class="marT20 order-content-item">
                <div class="fl">{{ '订单编号' | formatTrans }}： {{ ordersDetials.orderId }}</div>
                <div class="fl">{{ '下单时间' | formatTrans }}： {{ ordersDetials.orderCreateTime }}</div>
                <div class="fl">{{ '付款时间' | formatTrans }}： {{ ordersDetials.paymentTime }}</div>
              </div>
              <div class="clearb"></div>
            </div>
            <div class="order-table-content">
              <div class="order-t-item-wrap">
                <div
                  class="order-table-in"
                  v-for="(itemF,indexF) in ordersDetials.soldOrderByGoodsDetailsList"
                  :key="indexF"
                >
                  <div class="order-table-item">
                    <div class="goods-img">
                      <img :src="itemF.goodsImage" alt>
                    </div>
                    <div class="goods-info">
                      <div class="goods-name">{{ itemF.goodsName }}</div>
                      <div class="goods-attr">
                        <div v-for="(itemS,indexS) in itemF.specification" :key="indexS">
                          <template v-if="itemS.paramName == 'Colour'">
                            <span>{{ itemS.paramName | formatTrans }}：</span>
                            <el-tooltip :content="itemS.paramValue" placement="top">
                              <span class="img-wrap">
                                <img :src="itemS.paramImage" alt>
                              </span>
                            </el-tooltip>
                          </template>
                          <template v-if="itemS.paramName != 'Colour'">
                            <span>{{ itemS.paramName | formatTrans }}：</span>
                            <span>{{ itemS.paramValue }}</span>
                          </template>
                        </div>
                        <div>
                          <span>{{ 'Quantity' | formatTrans }}：</span>
                          <span>{{ itemF.orderGoodsNum }}</span>
                        </div>
                      </div>
                      <div class="goods-price">{{ curSymbol }}{{ itemF.goodsPrice }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="order-t-info">
                <div class="left">
                  <div
                    class="fir-item"
                    v-if="ordersDetials.soldOrderByGoodsDetailsList && ordersDetials.soldOrderByGoodsDetailsList[0].orderType==1"
                  >
                    <span class="sub-title">{{'小计' | formatTrans}}</span>
                    <span class="sub-val">{{ curSymbol }}{{ ordersDetials.orderGoodsAmount }}</span>
                  </div>
                  <div
                    class="sec-item"
                    v-if="ordersDetials.soldOrderByGoodsDetailsList && ordersDetials.soldOrderByGoodsDetailsList[0].orderType==1"
                  >
                    <span class="freight">{{'运费' | formatTrans}}: {{ curSymbol }}0</span>
                    <span class="tax-val">{{'税费' | formatTrans}}: {{ curSymbol }}0</span>
                    <span class="pay-way">{{'Online Payment' | formatTrans}}</span>
                  </div>
                </div>
                <div class="right-fir" v-show="ordersDetials.orderStatus == 6">
                  <span
                    class="detail-btn"
                    @click="showReFrame"
                    v-if="showRefBtn"
                  >{{ 'Refund' | formatTrans }}</span>
                  <span
                    class="detail-btn"
                    @click="checkDetail"
                    v-else
                  >{{ 'After-sale Details' | formatTrans }}</span>
                </div>
                <div class="right-sec" v-show="ordersDetials.orderStatus != 6 && !showRefBtn">
                  <template v-for="item in ordersDetials.soldOrderByGoodsDetailsList">
                    <div v-if="item.goodsRefundStatus == 2">
                      <span
                        class="detail-btn"
                        @click="checkDetail"
                      >{{ 'After-sale Details' | formatTrans }}</span>
                    </div>
                    <div v-else>
                      <span class="detail-btn" @click="showReFrame">{{ 'Refund' | formatTrans }}</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- 在这里放置底部的内容 -->
      <div class="box">
        <div class="copyRight">
          <div
            class="copyRightContent"
          >{{'版权所有 © 2021-2024 保留所有权利.技术支持：江苏鹤都云人工智能科技有限公司' | formatTrans}}</div>
        </div>
      </div>
    </div>

    <div class="ref-frame" v-if="refGoodsShow">
      <div class="title">
        <span>{{'Select Return Item' | formatTrans}}</span>
        <img :src="closeImg" @click="hideReFrame">
      </div>
      <div class="all-goods-wrap">
        <div class="all-goods-in">
          <div
            class="item"
            v-for="(item, index) in refGoodsArr"
            :key="index"
            @click="refGCheck(item)"
          >
            <div class="select-img">
              <img :src="selectedGou" v-show="item.goodsRefundStatus != 2 && item.isSelected">
              <img :src="selectedNo" v-show="item.goodsRefundStatus != 2 && !item.isSelected">
              <img :src="selectedCant" v-show="item.goodsRefundStatus == 2">
            </div>
            <div class="ref-goods-img">
              <img :src="item.goodsImage">
            </div>
            <div class="ref-goods-info">
              <div class="goods-name">{{ item.goodsName }}</div>
              <div class="goods-attr">
                <div v-for="(itemS,indexS) in item.specification" :key="indexS">
                  <template v-if="itemS.paramName == 'Colour'">
                    <span>{{ itemS.paramName | formatTrans }}：</span>
                    <el-tooltip :content="itemS.paramValue" placement="top">
                      <span class="img-wrap">
                        <img :src="itemS.paramImage" alt>
                      </span>
                    </el-tooltip>
                  </template>
                  <template v-if="itemS.paramName != 'Colour'">
                    <span>{{ itemS.paramName | formatTrans }}：</span>
                    <span>{{ itemS.paramValue | formatTrans }}</span>
                  </template>
                </div>
                <div>
                  <span>{{ 'Quantity' | formatTrans }}：</span>
                  <span>{{ item.orderGoodsNum }}</span>
                </div>
              </div>
              <div class="goods-price">{{ curSymbol }}{{ item.goodsPrice }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrap">
        <div class="cancel-btn s-btn" @click="cancelSel">
          <span>Cancel</span>
        </div>
        <div class="conf-btn s-btn" @click="comfirSel">
          <span>Confirm</span>
        </div>
      </div>
    </div>

    <div class="shadow" v-if="refGoodsShow" @click="hideReFrame"></div>
  </div>
</template>
  <script>
import { globalVari } from "@/utils/variable";
import { enquireSoldOrders } from "@/api/index";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import shoppingBgImg from "@/assets/images/shoppingBg.png";
import closeImg from "@/assets/images/close-ref.png";
import selectedGou from "@/assets/images/selected-gou.png";
import selectedNo from "@/assets/images/selected-no.png";
import selectedCant from "@/assets/images/selected-cant.png";
import { getTrans } from "@/api/set.js";

export default {
  name: "HasGetGoDetail",
  components: {
    Header,
    Footer
  },

  data() {
    return {
      closeImg,
      shoppingBgImg,
      selectedGou,
      selectedNo,
      selectedCant,
      shopId: globalVari.shopId, // 店铺id
      orderId: "",
      curSymbol: "",
      ordersDetials: "", //订单详情信息
      refGoodsShow: false,
      refGoodsArr: "",
      activeRefG: [],
      checkListArr: [],
      showRefBtn: true
    };
  },

  mounted() {
    try {
      this.curSymbol = sessionStorage.getItem("currencySymbol");
      this.getOrderDetails();
    } catch (error) {}
  },

  activated() {
    try {
      this.curSymbol = sessionStorage.getItem("currencySymbol");
      this.getOrderDetails();
    } catch (error) {}
  },

  methods: {
    contactUsFn() {
      this.$router.push("/ContactUs");
    },

    //获取订单详情
    getOrderDetails() {
      let obj = {
        token: sessionStorage.getItem("token"),
        orderId: "7240468844994995297"
      };

      enquireSoldOrders(obj).then(res => {
        if (res.code == 1) {
          this.ordersDetials = res.data;
          this.refGoodsArr = res.data.soldOrderByGoodsDetailsList;

          for (let i = 0; i < this.refGoodsArr.length; i++) {
            this.$set(this.refGoodsArr[i], "isSelected", false);
          }

          let flag = this.refGoodsArr.every(item => {
            return item.goodsRefundStatus != 2;
          });

          if (this.ordersDetials.orderStatus == 6 || flag) {
            this.showRefBtn = true;
          } else {
            this.showRefBtn = false;
          }
        }
      });
    },

    showReFrame() {
      this.refGoodsShow = true;
    },

    cancelSel() {
      this.refGoodsShow = false;
    },

    comfirSel() {
      let newArr = [];
      for (let i = 0; i < this.refGoodsArr.length; i++) {
        if (this.refGoodsArr[i].isSelected) {
          newArr.push(this.refGoodsArr[i]);
        }
      }

      this.$router.push({
        path: "/SupRTSelect",
        query: {
          checkData: JSON.stringify(newArr)
        }
      });
    },

    checkDetail() {},

    hideReFrame() {
      this.refGoodsShow = false;
    },

    refGCheck(param) {
      for (let i = 0; i < this.refGoodsArr.length; i++) {
        if (!this.refGoodsArr[i].isSelected) {
          if (this.refGoodsArr[i].goodsId == param.goodsId) {
            this.$set(this.refGoodsArr[i], "isSelected", true);
          }
        } else {
          if (this.refGoodsArr[i].goodsId == param.goodsId) {
            this.$set(this.refGoodsArr[i], "isSelected", false);
          }
        }
      }

      this.checkListArr = [];
      for (let i = 0; i < this.refGoodsArr.length; i++) {
        if (this.refGoodsArr[i].isSelected) {
          this.checkListArr.push(this.refGoodsArr[i].goodsId);
        }
      }
    }
  }
};
</script>
  <style lang="scss" scoped>
.HasGetGoDetail {
  padding: 100px 0;
  padding-bottom: 0;

  .footer {
    background: #1c0a00;
    width: 100%;

    .box {
      width: 1200px;
      margin: 0 auto;
      z-index: 99999;
      bottom: 0;
      height: 60px;
      background: #1c0a00;

      .copyRight {
        .copyRightContent {
          text-align: center;
          color: #c3bebc;
          font-size: 16px;
          line-height: 60px;
        }
      }
    }
  }

  .order-re-con {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;

    .order-table-wrap {
      .order-table-m {
        border-radius: 5px;
        border: 1px solid #ccc;
        margin-top: 24px;
      }

      .table-title {
        border-bottom: 1px solid #ccc;
        padding: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
        border-top-left-radius: 5px; /* 左上角圆角 */
        border-top-right-radius: 5px; /* 右上角圆角 */

        .Order-Content {
          display: inline-block;
          font-family: PingFangSC-Regular;
          color: rgba(0, 0, 0, 0.85);
          font-size: 18px;
        }

        .order-content-item {
          div {
            padding: 0 30px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
          }

          div:first-child {
            padding-left: 0;
          }
        }
      }

      .order-table-content {
        display: flex;
        justify-content: space-between;

        .order-t-item-wrap {
          width: 884px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .order-table-in {
          display: flex;
          border-top: 1px solid #ccc;
        }

        .order-table-in:nth-child(1) {
          border-top: none;
        }

        .order-table-item {
          width: 700px;
          display: flex;
          padding: 24px 0;
          border-top: 1px solid #ccc;

          .goods-img {
            width: 110px;
            height: 110px;
            background: rgba(243, 243, 245, 1);
            border-radius: 5px;
            margin-left: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 90%;
            }
          }

          .goods-info {
            margin-left: 13px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 2px 0;

            .goods-name {
              color: rgba(0, 0, 0, 0.85);
              font-size: 18px;
              font-weight: 550;
            }

            .goods-attr {
              display: flex;
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;

              div:nth-child(n) {
                margin-left: 49px;
              }

              div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-left: 0;

                .img-wrap {
                  width: 40px;
                  height: 24px;
                  background: rgba(243, 243, 245, 1);

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            .goods-price {
              color: rgba(127, 73, 42, 1);
              font-size: 18px;
            }
          }
        }

        .order-table-item:nth-child(1) {
          border-top: none;
        }

        .order-t-info {
          border-left: 1px solid #ccc;
          display: flex;
          justify-content: center;

          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 144px;
            padding: 24px 0;

            .fir-item {
              display: flex;
              flex-direction: column;
              text-align: center;

              .sub-title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
              }

              .sub-val {
                color: rgba(127, 73, 42, 1);
                font-size: 18px;
                margin-top: 8px;
              }
            }

            .sec-item {
              margin: 0 auto;
              margin-top: 12px;
              background: #f3f3f5;
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: rgba(0, 0, 0, 0.55);
              font-size: 12px;
              text-align: center;
              padding: 8px 0;

              .tax-val {
                margin-top: 4px;
                margin-bottom: 8px;
              }

              .pay-way {
                padding: 0 9px;
                padding-top: 8px;
                border-top: 1px solid rgba(217, 217, 217, 1);
              }
            }
          }

          .right-fir {
            width: 170px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #ccc;

            .detail-btn {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              cursor: pointer;
              border-bottom: 0.5px solid rgba(0, 0, 0, 0.85);
            }
          }

          .right-sec {
            width: 170px;
            display: flex;
            flex-direction: column;
            border-left: 1px solid #ccc;

            div {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              cursor: pointer;
              border-bottom: 0.5px solid rgba(0, 0, 0, 0.85);
              height: 158px;
              line-height: 158px;
              text-align: center;

              span {
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.85);
              }
            }
          }
        }
      }
    }
  }

  .titles {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .title-point {
      width: 8px;
      height: 8px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 50%;
      margin-right: 10px;
    }

    .titleBig {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
      font-family: PingFangSC-Medium;
    }
  }

  .titles-order {
    margin-top: 48px;
  }

  .order-status {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 21px 20px;

    .first-line {
      display: inline-block;
      padding: 3px 8px;
      background: rgba(185, 132, 101, 0.15);
      border-radius: 2px;
      color: rgba(185, 132, 101, 1);
      font-size: 12px;
      font-family: PingFangSC-Regular;
    }
  }

  .order-info {
    margin-top: 12px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 21px 20px;

    .first-line {
      display: inline-block;
      font-family: PingFangSC-Regular;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
    }

    .sec-line {
      margin-top: 20px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }

    .third-line {
      margin-top: 6px;
    }

    .contact-us {
      span {
        padding-bottom: 1px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.85);
      }
    }
  }

  .order-info-fir {
    margin-top: 24px;

    span {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      padding-right: 20px;
    }
  }

  .order-info-third {
    padding: 0;
    margin-top: -12px;
  }

  ::v-deep .el-collapse-item__header {
    font-size: 26px;
    padding: 10px 0;
  }

  .ref-frame {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100002;
    background: #fff;
    width: 950px;
    border-radius: 4px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      height: 56px;
      line-height: 56px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      border-bottom: 1px solid #eeeeee;

      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .all-goods-wrap {
      padding: 24px 24px;

      .all-goods-in {
        border: 1px solid #eeeeee;
        border-radius: 4px;

        .item {
          padding: 16px 16px;
          display: flex;
          height: 88px;
          border-bottom: 1px solid #eeeeee;
          cursor: pointer;

          .select-img {
            height: 100%;
            display: flex;
            align-items: center;

            img {
              width: 18px;
              height: 18px;
            }
          }

          .ref-goods-img {
            width: 88px;
            height: 88px;
            background: rgba(243, 243, 245, 1);
            border-radius: 5px;
            margin-left: 16px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .ref-goods-info {
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 2px 0;

            .goods-name {
              font-size: 18px;
              font-weight: bold;
            }

            .goods-attr {
              display: flex;
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;

              div:nth-child(n) {
                margin-left: 49px;
              }

              div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-left: 0;

                .img-wrap {
                  width: 40px;
                  height: 24px;
                  background: rgba(243, 243, 245, 1);

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            .goods-price {
              color: rgba(127, 73, 42, 1);
              font-size: 18px;
            }
          }
        }

        .item:last-child {
          border-bottom: none;
        }
      }
    }

    .btn-wrap {
      height: 62px;
      border-top: 1px solid #eeeeee;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .s-btn {
        width: 126px;
        height: 38px;
        border-radius: 29px;
        font-size: 16px;
        text-align: center;
        line-height: 38px;
      }

      .cancel-btn {
        border: 1px solid rgba(185, 132, 101, 1);
        color: rgba(185, 132, 101, 1);
        margin-right: 8px;
      }

      .conf-btn {
        color: #fff;
        background: rgba(185, 132, 101, 1);
        margin-right: 24px;
      }
    }
  }

  .shadow {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100001;
    background: rgba(0, 0, 0, 0.55);
  }
}
</style>
    
    