<template>
  <div class="Payment">
    <Header/>
    <div class="paym-top-space-line"></div>
    <div class="paym-wrap">
      <div class="left-wrap">
        <div class="step-t-f">
          <span class="el-icon-success"></span>
          <span class="step-t-word">{{ '收货地址' | formatTrans }}</span>
        </div>
        <div class="user-info">
          <div>
            <span>{{addrListsdatas.firstName | formatTrans}}</span>
            <span>{{addrListsdatas.lastName | formatTrans}}，</span>
            <span v-if="addrListsdatas.zipCode">{{ addrListsdatas.zipCode }}，</span>
            <span>{{ addrListsdatas.userAddressPhone }}</span>
          </div>
          <div class="sec-line">
            <span>{{addrListsdatas.userAddressAddress | formatTrans}}</span>
            <span>{{addrListsdatas.userAddressArea | formatTrans}}</span>
          </div>
        </div>

        <div class="stepN">
          <div class="step-t-s">
            <span class="proc-sec">2</span>
            <span class="proc-sec-word">{{ '支付' | formatTrans }}</span>
          </div>
          <div class="re-word-sts">PayPal, Sand Pay, ICBC, Offline Payment</div>
          <el-tabs type="border-card" class="pay-el-tab" v-model="activeTab" @tab-click="handleClick">
            <el-tab-pane name="PayPal"
              label="PayPal"
            >{{ payWord }}</el-tab-pane>
            <el-tab-pane name="Sand Pay"
              label="Sand Pay"
            >{{ payWord }}</el-tab-pane>
            <el-tab-pane name="ICBC"
              label="ICBC"
            >{{ payWord }}</el-tab-pane>
            <el-tab-pane name="Offline Payment"
              label="Offline Payment"
            >{{ payWord2 }}</el-tab-pane>
          </el-tabs>
        </div>
        <div class="btns marT20" v-show="!showBtn">
          <el-button
            @click="goPayment"
            style="width:30%;"
            type="primary"
            round
            class="btn"
          >{{'支付' | formatTrans}}</el-button>
        </div>
        <div class="btns marT20" v-show="showBtn">
          <el-button
            @click="goPayment"
            style="width:30%;"
            type="primary"
            round
            class="btn"
          >{{'去支付' | formatTrans}}</el-button>
        </div>
      </div>

      <div class="orderOverview">
        <div class="texc">{{'订单摘要' | formatTrans}}</div>
        <div v-for="(item,index) in goodsOrderList" :key="index" class="sin-goods-wrap">
          <div class="goods-img">
            <img :src="item.goodsImage">
          </div>
          <div class="goods-info-wrap">
            <div class="goods-name">{{ item.goodsName }}</div>

            <div class="sec-line">
              <div class="goods-price">{{ curSymbol }}{{ item.goodsPrice }}</div>
              <div class="goods-num">{{'数量' | formatTrans}}: {{item.goodsNum}}</div>
            </div>

            <div class="third-line">
              <span
                v-for="(itemF,indexF) in item.specification"
                :key="indexF"
                class="sin-guige-wrap"
              >
                <template v-if="itemF.paramName == 'Colour'">
                  <span>{{ itemF.paramName }}:</span>
                  <el-tooltip :content="itemF.paramValue" placement="top">
                    <span class="color-img">
                      <img :src="itemF.paramImage">
                    </span>
                  </el-tooltip>
                </template>
                <template v-else>{{ itemF.paramName }}: {{ itemF.paramValue }}</template>
              </span>
            </div>
          </div>
        </div>

        <div class="total-sta">
          <div class="item-tot">
            <div>{{'Amount' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ goodsTotPri }}</div>
          </div>

          <div class="item-tot">
            <div>{{'运费' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ freiVal }}</div>
          </div>

          <div class="item-tot">
            <div>{{'Tax' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ taxVal }}</div>
          </div>

          <div class="item-tot">
            <div>{{'合计' | formatTrans}}:</div>
            <div class="sub-price">{{ curSymbol }}{{ subVal }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="paym-bot-space-line"></div>
    <Footer/>
  </div>
</template>
<script>
import { globalVari } from "@/utils/variable";
import { appCreateOrders, logout } from "@/api/index";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import { getTrans } from "@/api/set.js";

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      activeTab: 'PayPal',
      showBtn: false,
      appCreateOrdersDatas: [],
      paymentDialog: false, // 支付对话框
      goodsIds: [],
      goodsBaseList: [],
      active: 0,
      radio: "1",
      addNew: "",
      formInline: {},
      addForm: {},
      addNewAddressForm: {},
      rules: {},
      goodsOrderList: [],
      addrListsdatas: "",
      goodsTotPri: 0,
      freiVal: 0,
      taxVal: 0,
      subVal: 0,
      curSymbol: "",
      payWord: getTrans("To continue your order with PayPal, please select 'CONFlRM DETAlLs'below. Then select 'Pay with PayPal'. You wil then be prompted to login toyour PayPal account. Once the transaction is complete you wil be directed back to the BAB!TINO site for your order confrmation"),
      payWord2: getTrans('To proceed with offline payment for orders, please click on "Confirm Details" on the right and select "Payment via Offline Payment". The system will provide you with offline payment information. Please complete the offline payment as soon as possible within the specified time.')
    };
  },

  created() {
    try {
      this.curSymbol = sessionStorage.getItem("currencySymbol");
      this.goodsOrderList = JSON.parse(
        sessionStorage.getItem("goodsListsdatas")
      );
      this.addrListsdatas = JSON.parse(
        sessionStorage.getItem("addressListsdatas")
      );
      this.getTotalData();
    } catch (error) {}
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },

  methods: {
    // tabs change 事件
    handleClick(tab,event){
      console.log(tab,event)
      if(tab.name == 'Offline Payment'){
        this.showBtn = true
      }else{
        this.showBtn = false
      }
    },
    
    getTotalData() {
      let totPri = 0;
      for (let i = 0; i < this.goodsOrderList.length; i++) {
        totPri +=
          this.goodsOrderList[i].goodsPrice * this.goodsOrderList[i].goodsNum;
      }

      this.goodsTotPri = totPri;
      this.subVal = totPri;
    },

    paymentUrlFn(val) {
      const url =
        "https://www.hdy33.com/pc/pages/hdypay.html?paymentnumber=" +
        val.paymentNumber +
        "&arg=" +
        sessionStorage.getItem("token") +
        "&opay=" +
        this.goodsOrderList[0].goodsTotalPrice +
        "&orderDetail={" +
        "token:" +
        sessionStorage.getItem("token") +
        "}";

      window.location.href = url;
    },

    paymentDialogCancel() {
      this.paymentDialog = false;
    },

    paymentSubmit() {},

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    increment() {
      this.quantity += 1; // 增加数量
    },

    decrement() {
      if (this.quantity > 1) {
        this.quantity -= 1; // 减少数量
      }
    },

    // save Address
    handleSaveChecked() {},

    //去支付
    goPayment() {
      for (let i = 0; i < this.goodsOrderList.length; i++) {
        this.goodsIds.push(this.goodsOrderList[i].goodsId);

        this.goodsBaseList.push({
          goodsId: this.goodsOrderList[i].goodsId,
          goodsName: this.goodsOrderList[i].goodsName,
          goodsNum: this.goodsOrderList[i].goodsNum
        });
      }

      // 下单
      let obj = {
        token: sessionStorage.getItem("token"),
        goodsIds: this.goodsIds,
        orderReceiverAddress: this.addrListsdatas.userAddressAddress,
        orderReceiverAreaId: this.addrListsdatas.userAddressAreaId,
        orderReceiverCityId: this.addrListsdatas.userAddressCityId,
        orderReceiverContact: this.addrListsdatas.userAddressPhone,
        orderReceiverName:
          this.addrListsdatas.firstName + this.addrListsdatas.lastName,
        orderReceiverProvinceId: this.addrListsdatas.userAddressProvinceId,
        paymentCode: 1,
        platForm: 1,
        redPacketId: 0,
        storeInformationList: [
          {
            goodsBaseList: this.goodsBaseList,
            couponId: 0,
            shopId: globalVari.shopId
          }
        ],
        virtualGoods: 1
      };

      appCreateOrders(obj).then(res => {
        if (res.code == 1 && res.data) {
          this.appCreateOrdersDatas = res.data;
          this.paymentUrlFn(res.data);
          // this.$router.push("/OrderHistory");
        } else if (res.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.Payment {
  .paym-top-space-line {
    height: 80px;
  }

  .paym-bot-space-line {
    height: 100px;
  }

  .paym-wrap {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;

    .left-wrap {
      margin-top: 50px;
      width: 870px;

      .step-t-f {
        color: rgba(0, 0, 0, 0.85);
        font-size: 24px;
        font-weight: bold;

        .step-t-word {
          margin-left: 12px;
        }
      }

      .user-info {
        margin-top: 24px;
        margin-left: 38px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;

        .sec-line {
          margin-top: 5px;
        }
      }

      .stepN {
        border-radius: 10px;
        margin-top: 48px;

        .step-t-s {
          font-size: 26px;
          font-weight: bold;

          .proc-sec {
            width: 26px;
            height: 26px;
            border: 2px solid rgba(0, 0, 0, 0.85);
            display: inline-block;
            text-align: center;
            line-height: 26px;
            border-radius: 50%;
          }

          .proc-sec-word {
            margin-left: 12px;
          }
        }

        .re-word-sts {
          margin-left: 40px;
          margin-top: 4px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 12px;
        }

        .pay-el-tab {
          margin-top: 32px;
        }
      }
    }

    .orderOverview {
      width: 300px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      margin-top: 54px;

      .texc {
        margin-top: 24px;
      }

      .sin-goods-wrap {
        width: 252px;
        margin: 0 auto;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding: 24px 0 20px 0;
        display: flex;

        .goods-img {
          width: 70px;
          height: 70px;
          background: rgba(243, 243, 245, 1);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 80%;
          }
        }

        .goods-info-wrap {
          width: 170px;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .goods-name {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: 550;
          }

          .sec-line {
            margin-top: 4px;
            display: flex;
            justify-content: space-between;

            .goods-price {
              color: rgba(127, 73, 42, 1);
              font-size: 14px;
            }

            .goods-num {
              color: rgba(0, 0, 0, 0.85);
              font-size: 12px;
            }
          }

          .third-line {
            display: flex;
            justify-content: space-between;
            font-size: 12px;

            .sin-guige-wrap {
              display: flex;
              align-items: center;

              .color-img {
                width: 33px;
                height: 20px;
                background: rgba(243, 243, 245, 1);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 4px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .total-sta {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        padding: 20px 0 20px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        width: 252px;
        margin: 0 auto;

        .item-tot {
          margin-top: 12px;
          display: flex;
          justify-content: space-between;
        }

        .item-tot:nth-child(1) {
          margin-top: 0;
        }

        .sub-price {
          color: rgba(127, 73, 42, 1);
          font-weight: bold;
        }
      }
    }
  }

  .btns {
    .button {
      color: #fff;
      background-color: #b98465;
      border-color: #b98465;
      font-size: 18px !important;
      margin-top: 20px;
    }
    .shopNow {
      color: #b98465;
      background-color: #fff;
      border-color: #b98465;
      font-size: 18px !important;
      margin-top: 20px;
    }
  }

  .sizeS {
    li:first-child {
      margin-left: 0;
    }
  }

  .stepS {
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    div:first-child {
      font-size: 20px;
      font-weight: bold;
      margin: 20px 0;
    }
    div:last-child {
      // font-size: 20px;
      // font-weight: bold;
      margin: 10px 0;
    }
  }

  ::v-deep .el-form-item__label {
    line-height: 60px;
  }
}
</style>

