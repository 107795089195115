<!-- 订单详情 -->
<template>
  <div class="OrderDetails">
    <Header/>
    <div class="order-re-con">
      <div class="titles">
        <div class="title-point"></div>
        <div class="titleBig">{{'订单状态' | formatTrans}}</div>
      </div>

      <div class="order-status">
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='1'"
          type="warning"
        >{{'待付款'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='3'"
          type="warning"
        >{{'待发货'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='4'"
          type="warning"
        >{{'待收货'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='6'"
          type="warning"
        >{{'确认收货'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='7'"
          type="warning"
        >{{'已取消'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='8'"
          type="warning"
        >{{'退款中'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='9'"
          type="warning"
        >{{'退款完成'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='11'"
          type="warning"
        >{{'待自提'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='12'"
          type="warning"
        >{{'虚拟订单已过期'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='16'"
          type="warning"
        >{{'部分发货'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='22'"
          type="warning"
        >{{'未发货退款中'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='23'"
          type="warning"
        >{{'收货退款中'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='24'"
          type="warning"
        >{{'收货退货中'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='25'"
          type="warning"
        >{{'退货完成'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.orderDetailStatus.orderStatus =='26'"
          type="warning"
        >{{'继续发货'| formatTrans}}</el-tag>

        <div v-show="this.orderDetailStatus.orderStatus =='1'">
          <div class="sec-line" v-for="(item,index) in soldOrdersDetials" :key="index">
            {{ '自动关闭交易还剩' | formatTrans }}
            <span class="auto-close">{{item.orderCancelTime}}</span>
          </div>
        </div>
      </div>

      <!-- <div class="titles titles-order">
        <div class="title-point"></div>
        <div class="titleBig">{{'物流信息' | formatTrans}}</div>
      </div>
      <div class="order-info order-info-fir">
        <div>
          <span>{{'Shipping Method: Express Delivery'}}</span>
          <span>{{'Logistics Company: Yunda Express'}}</span>
          <span>{{'Waybill Number:433968949735227'}}</span>
        </div>
      </div>-->

      <div class="titles titles-order">
        <div class="title-point"></div>
        <div class="titleBig">{{'订单信息' | formatTrans}}</div>
      </div>

      <div class="order-info order-info-fir">
        <div class="first-line">{{'邮寄地址' | formatTrans}}</div>
        <div
          class="sec-line"
        >{{ this.orderDetailStatus.orderReceiverName | formatTrans}}, {{ this.orderDetailStatus.orderReceiverContact | formatTrans}}</div>
        <div class="third-line">{{ this.orderDetailStatus.orderReceiverAddress | formatTrans}}</div>
      </div>

      <div class="order-info">
        <div class="first-line">{{'卖方信息' | formatTrans}}</div>
        <div class="sec-line contact-us">
          <a @click="ContactUsFn">{{ '联系我们' | formatTrans}}</a>
        </div>
      </div>

      <div class="order-info-third">
        <div class="order-table-wrap">
          <div class="order-table-m" v-for="(item,index) in soldOrdersDetials" :key="index">
            <div class="table-title">
              <div class="clearb"></div>
              <div class="Order-Content">{{'订单内容' | formatTrans}}</div>

              <div class="marT20 order-content-item">
                <div class="fl">{{ '订单编号' | formatTrans }}: {{ item.orderId }}</div>
                <div class="fl">{{ '下单时间' | formatTrans }}: {{ item.orderCreateTime }}</div>
                <div
                  v-show="item.orderStatus == 1"
                  class="countdown"
                >{{'剩余支付时间' | formatTrans}}: {{item.orderCancelTime}}</div>
                <div
                  v-show="item.orderStatus != 1"
                  class="fl"
                >{{ '支付时间' | formatTrans }}: {{ item.paymentTime }}</div>
              </div>
              <div class="clearb"></div>
            </div>
            <div class="order-table-content">
              <div class="order-t-item-wrap">
                <div
                  class="order-table-in"
                  v-for="(itemF,indexF) in item.soldOrderByGoodsDetailsList"
                  :key="indexF"
                >
                  <div class="order-table-item">
                    <div class="goods-img">
                      <img :src="itemF.goodsImage" alt>
                    </div>
                    <div class="goods-info">
                      <div class="goods-name">{{ itemF.goodsName }}</div>
                      <div class="goods-attr">
                        <div v-for="(itemS,indexS) in itemF.specification" :key="indexS">
                          <template v-if="itemS.paramName == 'Colour'">
                            <span>{{ itemS.paramName | formatTrans }}：</span>
                            <el-tooltip :content="itemS.paramValue" placement="top">
                              <span class="img-wrap">
                                <img :src="itemF.goodsImage" alt>
                              </span>
                            </el-tooltip>
                          </template>
                          <template v-if="itemS.paramName != 'Colour'">
                            <span>{{ itemS.paramName | formatTrans }}：</span>
                            <span>{{ itemS.paramValue | formatTrans }}</span>
                          </template>
                        </div>
                        <div>
                          <span>{{ '数量' | formatTrans }}：</span>
                          <span>{{ itemF.orderGoodsNum }}</span>
                        </div>
                      </div>
                      <div class="goods-price">{{ curSymbol }}{{ itemF.goodsPrice }}</div>
                    </div>
                  </div>
                  <div class="order-status-item">
                    {{ item.orderStatus |
                    formatOrderState(item.groupBuyStatus, item.paymentCode, item.reviewStatus,
                    item.orderRefundStatus, item.orderBuyerEvaluationStatus,itemF.shipmentStatus)
                    }}
                  </div>

                  <!-- <div class="order-status-item">
                    <a
                      class="detail-btn"
                      @click="ConfrmReceipt(item)"
                    >{{ itemF.goodsName | formatTrans }}</a>
                  </div>-->
                </div>
              </div>

              <div class="order-t-info">
                <div class="left">
                  <div class="fir-item">
                    <span class="sub-title">{{'小计' | formatTrans}}</span>
                    <span class="sub-val">{{ curSymbol }}{{ item.orderGoodsAmount }}</span>
                  </div>
                  <div class="sec-item">
                    <span class="freight">{{'运费' | formatTrans}}: {{ curSymbol }}0</span>
                    <span class="tax-val">{{'税费' | formatTrans}}: {{ curSymbol }}0</span>
                    <span class="payment-type">{{ item.paymentCode == 1 ? '线上支付' : '线下支付'}}</span>
                  </div>
                </div>
                <div class="right">
                  <u
                    v-show="item.orderStatus =='1'"
                    class="detail-btn"
                    @click="cancelOrderBtn(item,'取消订单')"
                  >{{ '取消订单' | formatTrans }}</u>
                  <u
                    v-show="item.orderStatus =='1'"
                    class="detail-btn"
                    @click="Payment(item)"
                  >{{'支付' | formatTrans}}</u>
                  <u
                    v-show="item.orderStatus =='3'"
                    class="detail-btn"
                    @click="RefundOnly(item)"
                  >{{'申请退款' | formatTrans}}</u>
                  <u
                    v-show="item.orderStatus =='4' || item.orderStatus =='25'"
                    class="detail-btn"
                    @click="viewLogistics(item)"
                  >{{'查看物流' | formatTrans}}</u>
                  <!-- <u
                    v-show="item.orderStatus =='4'"
                    class="detail-btn"
                    @click="Payment(item)"
                  >{{'申请售后' | formatTrans}}</u>-->
                  <u
                    v-show="item.orderStatus =='4'"
                    class="detail-btn"
                    @click="ConfirmTheGoodsFn(item)"
                  >{{'确认收货' | formatTrans}}</u>
                  <!-- <u
                    v-show="item.orderStatus =='4'"
                    class="detail-btn"
                    @click="Payment(item)"
                  >{{'延迟收货' | formatTrans}}</u>-->
                  <u
                    v-show="item.orderStatus =='7' || item.orderStatus =='9' || item.orderStatus =='25'"
                    class="detail-btn"
                    @click="cancelOrderBtn(item,'删除订单')"
                  >{{'删除' | formatTrans}}</u>
                  <u
                    v-show="item.orderStatus =='8'  || item.orderStatus =='22'"
                    class="detail-btn"
                    @click="afterSalesDetails(item)"
                  >{{'售后详情' | formatTrans}}</u>
                  <u
                    v-show=" item.orderStatus =='9' "
                    class="detail-btn"
                    @click="afterSalesDetails(item)"
                  >{{'查看退款' | formatTrans}}</u>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- 在这里放置底部的内容 -->
      <div class="box">
        <div class="copyRight">
          <div
            class="copyRightContent"
          >{{'版权所有 © 2021-2024 保留所有权利.技术支持：江苏鹤都云人工智能科技有限公司' | formatTrans}}</div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="RefundOnlydialogVisible"
      width="30%"
      icon="el-icon-message"
    >
      <span slot="title" class="dialog-title" style>
        <i class="el-icon-warning" style="color:#e6a23c"></i>
        温馨提示
        <!-- 这里是图标 -->
      </span>
      <span>操作后，将无法恢复。您确定要继续这个操作吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelbtn" round @click="RefundOnlydialogVisible = false">取 消</el-button>
        <el-button class="cancelbtnBg" round @click="cancelSumbit()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="物流信息" :visible.sync="logisticsdialogVisible" width="60%">
      <div class="boxBorder marB10">
        <div v-for="(item,index) in soldOrdersDetials" :key="index">
          <div v-for="(itemT,index) in item.soldOrderByGoodsDetailsList" :key="index +1">
            <!-- {{itemT}} -->
            <div class="flexSty">
              <img :src="itemT.goodsImage" alt>
              <div>
                <span class="goodsNameS">{{itemT.goodsName}}</span>
                <br>
                {{ '共' | formatTrans}}{{soldOrdersDetials[0].soldOrderByGoodsDetailsList.length}}{{ '件' | formatTrans}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="boxBorder">
        <div class="logistics-title">
          <img width="50%" src="@/assets/images/SF.png">
          <span>{{'SF Express'}}</span>
          <span>{{textToCopy}}</span>
          <a class="fr" @click="CopyFn">Copy</a>
        </div>
        <div class="logistics-progress-bar">
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in logisticsInfos.Infos"
              :key="index+2"
              :timestamp="activity.createTime"
              :color="index === 0 ? '#b98465' : ''"
              placement="top"
            >{{activity.info}}</el-timeline-item>
          </el-timeline>
          <!-- <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in logisticsInfos.Infos"
              :key="index+2"
              :timestamp="activity.createTime"
              :color="activity.color"
              placement="top"
            >{{activity.info}}</el-timeline-item>
          </el-timeline>-->
        </div>
        <div class="logistics-positioning">
          <img width="50%" src="@/assets/images/positioning.png">
          <div>
            <span>{{'Zhang Linghe,214000,18361657527'}}</span>
            <br>
            <span>{{'Liangxi District, Wuxi City, iangsu Province, China Mainland, Room 5602, Building A, Donglingxishang, Chong"an Street'}}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { globalVari } from "@/utils/variable";
import {
  getshopBrand,
  enquireSoldOrders,
  cancelOrder,
  deleteOrder,
  confirmTheGoods,
  selectOrderReturn,
  queryLogistics
} from "@/api/index";
// D:\公司项目\新的gitlab\app_hdy_web\src\views\layout\Header.vue
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import shoppingBgImg from "@/assets/images/shoppingBg.png";
import { getTrans } from "@/api/set.js";
// D:\公司项目\新的gitlab\app_hdy_web\src\components\PendingShipment\ComCountDown.vue

export default {
  name: "OrderDetails",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      timers: [],
      historyArr: [],
      hasDatas: "", //是否有数据
      shopId: globalVari.shopId, // 店铺id
      activeNames: ["01"],
      BrowseBrandList: [],
      shoppingBgImg,
      orderDetailHistory: {},
      orderHistoryLists: [],
      orderDetailStatus: {},
      curSymbol: "",
      soldOrdersDetials: [], //订单详情信息
      RefundOnlydialogVisible: false, // 仅退款弹框
      cancelOrderData: {},
      title: "",
      returnState: "",
      logisticsInformations: [],
      logisticsdialogVisible: false, //物流信息弹框
      textToCopy: "433322718825906", //物流单号
      logisticsInfos: {
        name: "SF Express",
        imgUrl: "",
        Infos: [
          {
            color: "#b98465",
            createTime: "2024-08-20-10:23:10",
            info:
              "您的包裹已由快递员签收。如果您有任何疑问，请联系快递员[高瑞凡，电话：17538290571感谢您使用顺丰速运，期待再次为您服务。）"
          },
          {
            createTime: "2024-08-20-12:23:10",
            info:
              "快递已移交给高瑞安，目前正在运输中（联系电话：17538290571，顺丰速运已开通“安全卡”保护您的手机信息，请随时接听！）"
          },
          {
            createTime: "2024-08-19-11:23:10",
            info: "快递已到达苏州相城凤阳路分店"
          },
          {
            createTime: "2024-08-18-10:23:10",
            info: "快递已从南京中转站出发"
          },
          {
            createTime: "2024-08-17-10:23:00",
            info: "快递已到达南京中转站"
          },
          {
            createTime: "2024-08-17-09:23:05",
            info: "已揽件"
          }
        ]
      },
      deadline2: Date.now() + 1000 * 60 * 60 * 24
    };
  },
  filters: {
    formatOrderState(
      orderStatus,
      groupStatus,
      payCode,
      reviewStatus,
      orderrefundstatus,
      order_buyer_evaluation_status,
      shipmentStatus
    ) {
      if (orderStatus == 1) {
        if (payCode == 2 && reviewStatus == 3) {
          return getTrans("被驳回");
        } else {
          return getTrans("待付款");
        }
      } else if (orderStatus == 2) {
        if (groupStatus == 1) {
          return getTrans("待成团");
        } else {
          return getTrans("待发货");
        }
      } else if (orderStatus == 3) {
        if (groupStatus == 1) {
          return getTrans("待成团");
        } else {
          return getTrans("待发货");
        }
      } else if (orderStatus == 4) {
        return getTrans("待收货");
      } else if (orderStatus == 5 || orderStatus == 6) {
        if (order_buyer_evaluation_status == 1) {
          return getTrans("已评价");
        } else {
          return getTrans("已完成");
        }
      } else if (orderStatus == 7) {
        return getTrans("已取消");
      } else if (orderStatus == 8) {
        return getTrans("退款中");
      } else if (orderStatus == 9) {
        if (orderrefundstatus == 2) {
          return getTrans("退款完成");
        } else {
          return getTrans("退货完成");
        }
      } else if (orderStatus == 11) {
        return getTrans("待自提");
      } else if (orderStatus == 16) {
        return {
          0: getTrans("待发货"),
          1: getTrans("部分发货"),
          2: getTrans("已发货")
        }[shipmentStatus];
      } else if (orderStatus == 22 || orderStatus == 23) {
        return getTrans("退款申请中");
      } else if (orderStatus == 24) {
        return getTrans("退货申请中");
      } else if (orderStatus == 25) {
        return getTrans("售后已处理");
      } else if (orderStatus == 26) {
        return getTrans("待发货");
      }
    }
    // formatPayState(status) {
    //   const sourceMap = {
    //     1: getTrans("余额支付"),
    //     2: getTrans("支付宝支付"),
    //     3: getTrans("微信支付"),
    //     4: getTrans("通联支付"),
    //     5: getTrans("健康账户余额支付")
    //   };
    //   return sourceMap[status];
    // },
    // formatPayState2(status) {
    //   const sourceMap = {
    //     1: getTrans("在线支付"),
    //     2: getTrans("线下支付"),
    //     3: getTrans("货到付款")
    //   };
    //   return sourceMap[status];
    // }
  },
  computed: {
    openArray() {
      return this.BrowseBrandList.map(o => o.id);
    }
  },
  mounted() {
    try {
      this.curSymbol = sessionStorage.getItem("currencySymbol");
      // this.orderHistoryLists.push(
      //   JSON.parse(sessionStorage.getItem("orderDetailsdatas"))
      // );
      // this.orderDetailStatus = JSON.parse(
      //   sessionStorage.getItem("orderDetailsdatas")
      // );
    } catch (error) {}
  },
  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },
  created() {
    this.historyArr = [];
    this.orderDetailHistory = JSON.parse(
      sessionStorage.getItem("orderDetailsdatas")
    );
    this.historyArr.push(this.orderDetailHistory);
    // console.log(
    //   "this.orderDetailHistory1111111111======>",
    //   this.orderDetailHistory
    // );
    this.enquireSoldOrdersFn();

    // this.orderHistoryLists.push(this.$route.params.rowData);
    // console.log("this.$route.params===>", this.$route.params);
    // console.log("this.orderHistoryLists===>", this.orderHistoryLists);
  },
  methods: {
    // 未支付倒计时
    countDown(item) {
      for (let i = 0; i < item.length; i++) {
        if (item[i].orderEndTime) {
          var timer = setInterval(() => {
            var time2 = +new Date(item[i].orderEndTime);
            let getDate = (time2 - +new Date()) / 1000;
            if (time2 - +new Date() > 0) {
              var h = parseInt(getDate / 60 / 60);
              h = h < 10 ? "0" + h : h; // 时
              var m = parseInt((getDate / 60) % 60);
              m = m < 10 ? "0" + m : m; // 分
              var s = parseInt(getDate % 60);
              s = s < 10 ? "0" + s : s; // 秒
              item[i].orderCancelTimes = h + ":" + m + ":" + s;
              this.$set(
                this.soldOrdersDetials[i],
                "orderCancelTime",
                item[i].orderCancelTimes
              );
            } else {
              return "";
            }
          }, 1000); //开启定时器
          this.timers.push(timer);
        } else {
          item[i].orderEndTime = "";
          this.$set(this.soldOrdersDetials[i], "orderCancelTime", "");
        }
      }
    },
    // 查看物流
    viewLogistics(val) {
      this.logisticsdialogVisible = true;
      // queryLogistics().then(res => {});
    },
    // 复制功能
    exeCommandCopyText(text) {
      try {
        const t = document.createElement("textarea");
        t.nodeValue = text;
        t.value = text;
        document.body.appendChild(t);
        t.select();
        document.execCommand("copy");
        document.body.removeChild(t);
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    // 复制按钮
    CopyFn() {
      let content = this.textToCopy; // 包含了换行符
      let flag = this.exeCommandCopyText(content);

      if (flag) {
        this.$message({
          message: getTrans("复制成功！"),
          type: "success",
          duration: 3000,
          customClass: "auto-mes-box"
        });
      } else {
        this.$message({
          message: getTrans("复制失败"),
          type: "error",
          duration: 3000,
          customClass: "auto-mes-box"
        });
      }
      console.log(flag ? "复制成功" : "复制失败");
    },
    // 查询退款申请
    selectOrderReturnFn() {
      let obj = {
        token: sessionStorage.getItem("token"),
        orderId: this.orderDetailHistory.orderId
      };
      selectOrderReturn(obj).then(res => {
        if (res.code == 1) {
          // this.returnCode = res.data.returnCode;
          this.returnState = res.data.returnState;
          // this.returnAddTime = res.data.returnAddTime;
        }
        if (this.returnState == "1") {
          this.$router.push("/RefundOnlyProcessing"); // 新发起等待卖家审核
        } else if (this.returnState == "2") {
          // 卖家审核通过
          this.$router.push("/RefundOnlySuccess");
        } else if (this.returnState == "3") {
          // 卖家审核不通过
          this.$router.push("/RefundOnlyFail");
        }
      });
    },
    // 售后详情
    afterSalesDetails(val) {
      sessionStorage.setItem("refundOnlyRowData", JSON.stringify(val));
      this.selectOrderReturnFn();
    },
    ContactUsFn() {
      this.$router.push("/ContactUs");
    },
    //获取订单详情
    enquireSoldOrdersFn() {
      let obj = {
        token: sessionStorage.getItem("token"),
        orderId: this.orderDetailHistory.orderId
      };
      enquireSoldOrders(obj).then(res => {
        if (res.code == 1 && res.data) {
          this.soldOrdersDetials = [];
          this.soldOrdersDetials.push(res.data);
          this.orderDetailStatus = res.data;
          // console.log("enquireSoldOrders===>res===>", res);
          // console.log("this.soldOrdersDetials===>>", this.soldOrdersDetials);

          for (let i = 0; i < this.soldOrdersDetials.length; i++) {
            this.soldOrdersDetials[i].orderCancelTimes = this.soldOrdersDetials[
              i
            ].orderEndTime;
          }
          this.countDown(this.historyArr);
        }
      });
    },

    // 取消订单/删除订单
    cancelOrderBtn(val, titleT) {
      this.cancelOrderData = val;
      this.RefundOnlydialogVisible = true;
      this.title = titleT;
    },
    // 确认取消订单/删除订单
    cancelSumbit() {
      if (this.title == "取消订单") {
        let obj = {
          token: sessionStorage.getItem("token"),
          orderId: this.cancelOrderData.orderId,
          orderCancelIdentity: 1
        };
        cancelOrder(obj).then(res => {
          if (res.code == 1) {
            this.$message({
              message: getTrans("取消成功！"),
              type: "success",
              duration: 3000,
              customClass: "auto-mes-box"
            });
          } else {
            this.$message({
              message: getTrans(res.message),
              type: "error",
              duration: 3000,
              customClass: "auto-mes-box"
            });
          }
          this.RefundOnlydialogVisible = false;
          this.enquireSoldOrdersFn();
        });
      } else if (this.title == "删除订单") {
        let obj = {
          token: sessionStorage.getItem("token"),
          orderId: this.cancelOrderData.orderId
        };
        deleteOrder(obj).then(res => {
          if (res.code == 1) {
            this.$message({
              message: getTrans("删除成功！"),
              type: "success",
              duration: 3000,
              customClass: "auto-mes-box"
            });
          } else {
            this.$message({
              message: getTrans(res.message),
              type: "error",
              duration: 3000,
              customClass: "auto-mes-box"
            });
          }
          this.RefundOnlydialogVisible = false;

          this.$router.push("/OrderHistory");
        });
      }
    },
    // 申请退款
    RefundOnly(val) {
      this.rowData = val;
      // console.log("refundOnlyRowData===>", val);
      sessionStorage.setItem("refundOnlyRowData", JSON.stringify(this.rowData));
      this.$router.push({
        name: "RefundOnly",
        params: {
          rowData: val
        }
      });
    },
    // 确认收货
    ConfirmTheGoodsFn(val) {
      let obj = {
        token: sessionStorage.getItem("token"),
        orderId: this.orderDetailHistory.orderId
      };
      confirmTheGoods(obj).then(res => {
        if (res.code == 1) {
          this.$message({
            message: getTrans("确认成功！"),
            type: "success",
            duration: 3000,
            customClass: "auto-mes-box"
          });
          this.$router.push("/ConfirmTheGoods");
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },
    // 去支付
    Payment(val) {
      const url =
        "https://www.hdy33.com/pc/pages/hdypay.html?paymentnumber=" +
        val.paymentNumber +
        "&arg=" +
        sessionStorage.getItem("token") +
        "&opay=" +
        val.orderTotalPrice +
        "&orderDetail={" +
        "token:" +
        sessionStorage.getItem("token") +
        "}";

      window.location.href = url;

      this.fetchData();

      // this.$router.push({ name: "OrderDetails", params: val });
    }
  }
};
</script>
<style lang="scss" scoped>
.OrderDetails {
  padding: 100px 0;
  padding-bottom: 0;
  .footer {
    background: #1c0a00;
    width: 100%;
    .box {
      width: 1200px;
      margin: 0 auto;
      // position: fixed;
      z-index: 99999;
      bottom: 0;
      height: 60px;
      background: #1c0a00;
      .copyRight {
        .copyRightContent {
          text-align: center;
          color: #c3bebc;
          font-size: 16px;
          line-height: 60px;
        }
      }
    }
  }
  .boxBorder {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.15);
    .logistics-title {
      padding: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      span {
        padding: 0 10px;
      }
      img {
        // display: block;
        width: 38px;
        height: 38px;
        vertical-align: middle;
      }
    }
    .logistics-progress-bar {
      padding: 20px;
    }
    .logistics-positioning {
      padding: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      span {
        line-height: 20px;
      }
      img {
        float: left;
        width: 38px;
        height: 38px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
  .flexSty {
    padding: 20px;
    display: flex;
    justify-content: start;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    // align-items: center;
    .goodsNameS {
      display: block;
    }
    img {
      width: 88px;
      height: 88px;
      border-radius: 6px;
      background-color: rgba(243, 243, 245, 1);
      margin-right: 20px;
    }
    span {
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
    }
  }
  .cancelbtn {
    color: #b98465;
    background-color: #fff;
    border-color: #b98465;
    margin-top: 20px;
    padding: 10px 30px;
    font-size: 16px;
  }
  .cancelbtn:hover {
    color: #fff;
    background-color: #b98465;
    border-color: #b98465;
    margin-top: 20px;
    padding: 10px 30px;
    font-size: 16px;
  }
  .cancelbtnBg {
    color: #fff;
    background-color: #b98465;
    border-color: #b98465;
    margin-top: 20px;
    padding: 10px 30px;
    font-size: 16px;
  }
  .cancelbtnBg:hover {
    color: #b98465;
    background-color: #fff;
    border-color: #b98465;
    margin-top: 20px;
    padding: 10px 30px;
    font-size: 16px;
  }
  .dialog-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
  }
  .order-re-con {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;
    .order-table-wrap {
      .order-table-m {
        border-radius: 5px;
        border: 1px solid #ccc;
        margin-top: 24px;
      }

      .table-title {
        border-bottom: 1px solid #ccc;
        // display: flex;
        // justify-content: space-between;
        padding: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
        border-top-left-radius: 5px; /* 左上角圆角 */
        border-top-right-radius: 5px; /* 右上角圆角 */
        .Order-Content {
          display: inline-block;
          font-family: PingFangSC-Regular;
          color: rgba(0, 0, 0, 0.85);
          font-size: 18px;
        }
        .order-content-item {
          div {
            padding: 0 30px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
          }

          div:first-child {
            padding-left: 0;
          }
        }
      }

      .order-table-content {
        display: flex;
        justify-content: space-between;

        .order-t-item-wrap {
          width: 884px;
        }

        .order-table-in {
          display: flex;
          border-top: 1px solid #ccc;
        }

        .order-table-in:nth-child(1) {
          border-top: none;
        }

        .order-table-item {
          width: 700px;
          display: flex;
          padding: 24px 0;
          border-top: 1px solid #ccc;

          .goods-img {
            width: 110px;
            height: 110px;
            background: rgba(243, 243, 245, 1);
            border-radius: 5px;
            margin-left: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 90%;
            }
          }

          .goods-info {
            margin-left: 13px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 2px 0;

            .goods-name {
              color: rgba(0, 0, 0, 0.85);
              font-size: 18px;
              // font-weight: 550;
            }

            .goods-attr {
              display: flex;
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;

              div:nth-child(n) {
                margin-left: 49px;
              }

              div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-left: 0;

                .img-wrap {
                  width: 40px;
                  height: 24px;
                  background: rgba(243, 243, 245, 1);

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            .goods-price {
              color: rgba(127, 73, 42, 1);
              font-size: 18px;
            }
          }
        }

        .order-table-item:nth-child(1) {
          border-top: none;
        }
        .order-status-item {
          display: flex;
          border-left: 1px solid #ccc;
          justify-content: center;
          width: 184px;
          flex-direction: column;
          text-align: center;
        }
        .order-t-info {
          border-left: 1px solid #ccc;
          display: flex;
          justify-content: center;

          .Refund {
            width: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            border-right: 1px solid #ccc;

            .goods-status {
              padding: 3px 4px;
              border-radius: 2px;
              background-color: rgba(185, 132, 101, 0.15);
              color: rgba(185, 132, 101, 1);
              font-size: 12px;
              margin: 0 auto;
            }

            .detail-btn {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              margin: 3px 0;
              cursor: pointer;
            }
          }
          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 144px;

            .fir-item {
              display: flex;
              flex-direction: column;
              text-align: center;

              .sub-title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
              }

              .sub-val {
                color: rgba(127, 73, 42, 1);
                font-size: 18px;
                margin-top: 8px;
              }
            }

            .sec-item {
              margin: 0 auto;
              margin-top: 12px;
              background: rgba(243, 243, 245, 1);
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: rgba(0, 0, 0, 0.55);
              font-size: 12px;
              text-align: center;
              width: 96px;
              height: 72px;

              .tax-val {
                margin-top: 4px;
              }
              .payment-type {
                margin-top: 5px;
                border-top: 1px solid rgba(217, 217, 217, 1);
              }
            }
          }

          .right {
            width: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            border-left: 1px solid #ccc;

            .goods-status {
              padding: 3px 4px;
              border-radius: 2px;
              background-color: rgba(185, 132, 101, 0.15);
              color: rgba(185, 132, 101, 1);
              font-size: 12px;
              margin: 0 auto;
            }

            .detail-btn {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              margin: 3px 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .titles {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .title-point {
      width: 8px;
      height: 8px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 50%;
      margin-right: 10px;
    }

    .titleBig {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
      font-family: PingFangSC-Medium;
    }
  }

  .titles-order {
    margin-top: 48px;
  }

  .order-status {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 21px 20px;

    .first-line {
      display: inline-block;
      padding: 3px 8px;
      background: rgba(185, 132, 101, 0.15);
      border-radius: 2px;
      color: rgba(185, 132, 101, 1);
      font-size: 12px;
      font-family: PingFangSC-Regular;
    }

    .sec-line {
      margin-top: 12px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }
  }

  .order-info {
    margin-top: 12px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 21px 20px;

    .first-line {
      display: inline-block;
      font-family: PingFangSC-Regular;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
    }

    .sec-line {
      margin-top: 20px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }

    .third-line {
      margin-top: 6px;
    }
  }

  .order-info-fir {
    margin-top: 24px;
    span {
      // width: 25%;
      // display: block;
      // float: left;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      padding-right: 20px;
    }
  }

  .order-info-third {
    padding: 0;
    margin-top: -12px;
    .info-top {
      padding: 21px 20px;
    }

    .sec-line {
      display: flex;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      .sec-span {
        margin-left: 100px;
      }
    }

    .info-bot {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: start;
      height: 158px;

      .left {
        display: flex;
        align-items: center;
        width: 50%;
        img {
          width: 110px;
        }

        .name-attr-price {
          display: flex;
          flex-direction: column;
          margin-left: 13px;

          .goods-title {
            color: rgba(0, 0, 0, 0.85);
            font-size: 18px;
          }

          .goods-attr-wrap {
            display: flex;
            font-size: 16px;
            margin-top: 16px;
          }

          .goods-price {
            color: rgba(127, 73, 42, 1);
            font-size: 18px;
            margin-top: 16px;
          }
        }
      }

      .right {
        display: flex;
        border-left: 1px solid rgba(0, 0, 0, 0.15);

        .sub-total {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-left: 24px;
          padding-right: 24px;
          width: 50%;
          .fir-itme {
            display: flex;
            flex-direction: column;
            align-items: center;

            .sub-title {
              font-size: 16px;
            }

            .sub-val {
              color: rgba(127, 73, 42, 1);
              font-size: 18px;
              margin-top: 4px;
            }
          }

          .sec-item {
            display: flex;
            flex-direction: column;
            padding: 8px 9px;
            background: rgba(243, 243, 245, 1);
            text-align: center;
            margin-top: 12px;
            font-size: 12px;

            .tax-val {
              margin-top: 4px;
            }
          }
        }

        .operate-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          padding: 0 36px;

          .pay-btn {
            margin-top: 16px;
          }
        }
      }
    }
  }

  .line {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background: #999;
  }

  img {
    width: 80%;
  }

  ::v-deep .el-collapse-item__header {
    font-size: 26px;
    padding: 10px 0;
  }

  .itemsS {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .latest-item .el-timeline-item__content {
    color: blue !important; /* 设置蓝色 */
  }
  /* 设置时间轴时间的字体大小 */
  ::v-deep .el-timeline-item__timestamp {
    font-size: 14px !important; /* 这里设置你想要的字体大小 */
    color: rgba(0, 0, 0, 0.45);
  }
  ::v-deep .el-timeline-item__content {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }
  /* 直接对第一个子元素应用CSS样式 */
  ::v-deep .el-timeline-item:first-child .el-timeline-item__timestamp {
    color: #b98465; /* 设置为您想要的颜色 */
  }
  .countdown {
    color: red !important;
    font-weight: bold;
  }
  .auto-close {
    color: #b98465;
    font-weight: bold;
  }
}
</style>
  
  