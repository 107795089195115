<!-- 仅退款 -->
<template>
  <div class="RefundOnly">
    <Header/>
    <div class="shipa-top-space-line">&nbsp;</div>
    <div class="ship-a-wrap">
      <div class="wrap-left">
        <div class="step-t-fir">
          <span class="proc-fir">1</span>
          <span class="step-t-word">{{'买方仅要求退款' | formatTrans}}</span>
        </div>
        <div class="re-word-stf">{{'如果您遇到任何问题，请联系卖家' | formatTrans}}</div>
        <div class="sin-info-wrap">
          <div class="addr-info-wrap">
            <div class="clearb"></div>
            <div class="refundTitle">退款</div>

            <div class="order-info-third">
              <div class="order-table-wrap">
                <div class="order-table-m" v-for="(item,index) in refundOnlyRowDatas" :key="index">
                  <div class="order-table-content">
                    <div class="order-t-item-wrap">
                      <div
                        class="order-table-in"
                        v-for="(itemF,indexF) in item.soldOrderByGoodsDetailsList"
                        :key="indexF"
                      >
                        <div class="order-table-item">
                          <div class="goods-img">
                            <img :src="itemF.goodsImage" alt>
                          </div>
                          <div class="goods-info">
                            <div class="goods-name">{{ itemF.goodsName | formatTrans }}</div>
                            <div class="goods-attr">
                              <div v-for="(itemS,indexS) in itemF.specification" :key="indexS">
                                <template v-if="itemS.paramName == 'Colour'">
                                  <span>{{ itemS.paramName | formatTrans }}：</span>
                                  <el-tooltip :content="itemS.paramValue" placement="top">
                                    <span class="img-wrap">
                                      <img :src="itemF.goodsImage" alt>
                                    </span>
                                  </el-tooltip>
                                </template>
                                <template v-if="itemS.paramName != 'Colour'">
                                  <span>{{ itemS.paramName | formatTrans }}：</span>
                                  <span>{{ itemS.paramValue | formatTrans }}</span>
                                </template>
                              </div>
                              <div>
                                <span>{{ '数量' | formatTrans }}：</span>
                                <span>{{ itemF.orderGoodsNum }}</span>
                              </div>
                            </div>
                            <div class="goods-price">{{ curSymbol }}{{ itemF.goodsPrice }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="clearb"></div>
            <div>
              <div class="clearb"></div>
              <p class="refundTitle">
                {{'退款原因' | formatTrans}}：
                <el-select
                  @change="returnReasonSelect(selectedValue)"
                  v-model="selectedValue"
                  size="mini"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in returnReasonOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </p>
              <p class="refundTitle marT20">{{'服务类型' | formatTrans}}：{{'仅退款' | formatTrans}}</p>
              <p
                class="refundTitle marT20"
              >{{'退款金额' | formatTrans}}：{{ curSymbol }}{{this.refundOnlyRowDatasT.orderGoodsAmount}}</p>
            </div>
          </div>

          <div class="btns">
            <el-button
              @click="sumbitRefundOnly"
              style="width:20%;"
              type="primary"
              round
              class="button"
            >{{'提交' | formatTrans}}</el-button>
          </div>
        </div>
        <div class="stepN">
          <div class="step-t-sec">
            <span class="proc-sec">2</span>
            <span class="proc-sec-word">{{'卖家正在处理退款请求' | formatTrans}}</span>
          </div>
          <div class="re-word-sts">{{'请耐心等待处理' | formatTrans}}</div>
        </div>
        <div class="stepN">
          <div class="step-t-sec">
            <span class="proc-sec">3</span>
            <span class="proc-sec-word">{{'退款已完成' | formatTrans}}</span>
          </div>
          <div class="re-word-sts">{{'返回原路线' | formatTrans}}</div>
        </div>
      </div>

      <div class="orderOverview">
        <div
          class="texc"
        >{{ '订单汇总' | formatTrans }}({{ this.refundOnlyRowDatasT.soldOrderByGoodsDetailsList.length }})</div>
        <div
          v-for="(item,index) in this.refundOnlyRowDatasT.soldOrderByGoodsDetailsList"
          :key="index"
          class="sin-goods-wrap"
        >
          <div class="goods-img">
            <img :src="item.goodsImage">
          </div>
          <div class="goods-info-wrap">
            <div class="goods-name">{{ item.goodsName }}</div>

            <div class="sec-line">
              <div class="goods-price">{{ curSymbol }}{{ item.goodsPrice }}</div>
              <div class="goods-num">{{'QTY' | formatTrans}}: {{item.orderGoodsNum}}</div>
            </div>

            <div class="third-line">
              <span
                v-for="(itemF,indexF) in item.specification"
                :key="indexF"
                class="sin-guige-wrap"
              >
                <template v-if="itemF.paramName == 'Colour'">
                  <span>{{ itemF.paramName | formatTrans }}:</span>
                  <el-tooltip :content="itemF.paramValue" placement="top">
                    <span class="color-img">
                      <img :src="itemF.paramImage">
                    </span>
                  </el-tooltip>
                </template>
                <template v-else>{{ itemF.paramName }}: {{ itemF.paramValue }}</template>
              </span>
            </div>
          </div>
        </div>

        <div class="total-sta">
          <div class="item-tot">
            <div>{{'金额' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ this.refundOnlyRowDatasT.orderGoodsAmount }}</div>
          </div>

          <div class="item-tot">
            <div>{{'运费' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ freiVal }}</div>
          </div>

          <div class="item-tot">
            <div>{{'税费' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ taxVal }}</div>
          </div>

          <div class="item-tot">
            <div>{{'小计' | formatTrans}}</div>
            <div class="sub-price">{{ curSymbol }}{{ this.refundOnlyRowDatasT.orderGoodsAmount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="shipa-bot-space-line">&nbsp;</div>
    <Footer/>
  </div>
</template>
<script>
import { applyRefund, cancelReasonList } from "@/api/index";
import { getTrans } from "@/api/set.js";
import AddAddress from "@/components/AddressBook/AddAddress.vue";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import country from "@/utils/country.json";
const originList = country;
export default {
  components: {
    AddAddress,
    Header,
    Footer
  },
  data() {
    return {
      refundOnlyRowDatas: [],
      traversed: false, // 标识是否已经遍历过
      country_list: originList,
      isDisabled: true,
      hasAddress: false, //是否有地址
      active: 0,
      selectedValue: "",
      radioVal: -1,
      addNew: "",
      addressForm: {},
      addressLists: [],
      addressListsdatas: {},
      rules: {},
      goodsInfo: [],
      pageType: 1,
      goodsTotPri: 0,
      freiVal: 0,
      taxVal: 0,
      subVal: 0,
      curSymbol: "",
      refundOnlyRowDatasT: {},
      returnReasonOptions: [],
      returnReasonId: "",
      returnReason: ""
    };
  },

  created() {
    this.cancelReasonListFn();
  },
  mounted() {
    try {
      this.curSymbol = sessionStorage.getItem("currencySymbol");
      this.refundOnlyRowDatas.push(
        JSON.parse(sessionStorage.getItem("refundOnlyRowData"))
      );
      this.refundOnlyRowDatasT = JSON.parse(
        sessionStorage.getItem("refundOnlyRowData")
      );
    } catch (error) {}
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },
  watch: {},
  methods: {
    cancelReasonListFn() {
      let obj = {
        token: sessionStorage.getItem("token"),
        orderReturnReasonType: 1
      };
      cancelReasonList(obj).then(res => {
        if (res.code == 1) {
          res.data.forEach(element => {
            this.returnReasonOptions.push({
              value: element.cancelReasonId,
              label: element.cancelReasonContent
            });
          });
        }
      });
    },
    // 取消
    CancelFn() {
      this.radioVal = -1;
      this.$refs.addressForm.resetFields();
      this.$set(this.addressForm, "country", "");
    },

    selectRad() {
      this.radioVal = 0;
    },

    // 退款原因选择
    returnReasonSelect(val) {
      this.returnReasonId = val;
      this.returnReasonOptions.forEach((item, index) => {
        if (item.value == val) {
          this.returnReason = item.label;
        }
      });
    },

    // 提交
    sumbitRefundOnly() {
      let obj = {
        returnReasonId: this.returnReasonId,
        returnReason: this.returnReason,
        token: sessionStorage.getItem("token"),
        orderId: this.refundOnlyRowDatasT.orderId
      };
      applyRefund(obj).then(res => {
        if (res.code == 1) {
          this.$message({
            message: getTrans("提交成功！"),
            type: "success",
            duration: 3000,
            customClass: "auto-mes-box"
          });
          this.$router.replace({
            path: "/RefundOnlyProcessing"
          });
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
      sessionStorage.setItem(
        "refundOnlyRowDatasSubmit",
        JSON.stringify(this.refundOnlyRowDatasT)
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.RefundOnly {
  .shipa-top-space-line {
    height: 80px;
  }

  .shipa-bot-space-line {
    height: 100px;
  }

  .ship-a-wrap {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 50px;

    .wrap-left {
      width: 870px;

      .step-t-fir {
        font-size: 26px;
        font-weight: bold;

        .proc-fir {
          width: 26px;
          height: 26px;
          display: inline-block;
          text-align: center;
          line-height: 26px;
          border: 2px solid rgba(0, 0, 0, 0.85);
          border-radius: 50%;
        }

        .step-t-word {
          margin-left: 12px;
        }
      }

      .re-word-stf {
        margin-left: 40px;
        margin-top: 4px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
      }

      .sin-info-wrap {
        margin-top: 28px;

        .addr-info-wrap {
          // display: flex;
          padding: 20px 24px;
          border: 1px solid #ccc;
          border-radius: 10px;
          cursor: pointer;
          margin-top: 12px;

          .left {
            .radio-wrap {
              width: 16px;
              height: 16px;
              border: 1px solid rgba(0, 0, 0, 0.3);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;

              .radio-point {
                width: 6px;
                height: 6px;
                background-color: rgba(185, 132, 101, 1);
                border-radius: 50%;
              }
            }

            .rad-active {
              border: 1px solid rgba(185, 132, 101, 1);
            }
          }

          .right {
            margin-left: 12px;

            .first-line {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              font-weight: 550;
            }

            .sec-line,
            .third-line {
              color: rgba(0, 0, 0, 0.85);
              font-size: 12px;
            }

            .sec-line {
              margin-top: 12px;
            }

            .third-line {
              margin-top: 6px;
            }
          }
        }

        .addr-info-wrap:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .stepS {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px 0;
    cursor: pointer;

    .first-line {
      display: flex;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 24px;

      .radio-wrap {
        width: 16px;
        height: 16px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .radio-point {
          width: 6px;
          height: 6px;
          background-color: rgba(185, 132, 101, 1);
          border-radius: 50%;
        }
      }

      .rad-active {
        border: 1px solid rgba(185, 132, 101, 1);
      }

      .right {
        margin-left: 12px;
      }
    }

    .addr-form-wrap {
      width: 60%;
      margin: 0 auto;
      margin-top: 24px;
      padding-left: 24px;
      padding-bottom: 20px;
    }

    .footerBtn {
      display: flex;
      justify-content: space-around;
      justify-items: center;

      .cancelbutton {
        color: #2c3e50;
        background-color: #fff;
        border-color: #ccc;
        font-size: 18px !important;
        margin-top: 20px;
      }

      .button {
        color: #fff;
        background-color: #b98465;
        border-color: #b98465;
        font-size: 18px !important;
        margin-top: 20px;
        margin-bottom: 48px;
      }
    }
  }

  .btns {
    .button {
      color: #fff;
      background-color: #b98465;
      border-color: #b98465;
      font-size: 16px !important;
      margin-top: 28px;
      margin-bottom: 48px;
    }
  }

  .stepN {
    border-radius: 10px;
    padding: 20px 24px;
    margin-top: 18px;
    background: rgba(243, 243, 245, 1);

    .step-t-sec {
      font-size: 26px;
      font-weight: bold;
      opacity: 0.45;

      .proc-sec {
        width: 26px;
        height: 26px;
        border: 2px solid rgba(0, 0, 0, 0.85);
        display: inline-block;
        text-align: center;
        line-height: 26px;
        border-radius: 50%;
      }

      .proc-sec-word {
        margin-left: 12px;
      }
    }

    .re-word-sts {
      margin-left: 40px;
      margin-top: 4px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
      opacity: 0.45;
    }
  }

  .orderOverview {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 300px;

    .texc {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .sin-goods-wrap {
      width: 252px;
      margin: 0 auto;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      padding: 24px 0 20px 0;
      display: flex;

      .goods-img {
        width: 70px;
        height: 70px;
        background: rgba(243, 243, 245, 1);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 80%;
        }
      }

      .goods-info-wrap {
        width: 170px;
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .goods-name {
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          // font-weight: 550;
        }

        .sec-line {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;

          .goods-price {
            color: rgba(127, 73, 42, 1);
            font-size: 14px;
          }

          .goods-num {
            color: rgba(0, 0, 0, 0.85);
            font-size: 12px;
          }
        }

        .third-line {
          display: flex;
          justify-content: space-between;
          font-size: 12px;

          .sin-guige-wrap {
            display: flex;
            align-items: center;

            .color-img {
              width: 33px;
              height: 20px;
              background: rgba(243, 243, 245, 1);
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 4px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .total-sta {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      padding: 20px 0 20px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      width: 252px;
      margin: 0 auto;

      .item-tot {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
      }

      .item-tot:nth-child(1) {
        margin-top: 0;
      }

      .sub-price {
        color: rgba(127, 73, 42, 1);
        font-weight: bold;
      }
    }
  }

  ::v-deep .el-form-item__label {
    line-height: 60px;
  }

  ::v-deep .el-input {
    position: relative;
    font-size: 14px;
  }
  .refundTitle {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }
  .order-info-third {
    padding: 0;
    // margin-top: -12px;
    .info-top {
      padding: 21px 20px;
    }

    .sec-line {
      display: flex;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      .sec-span {
        margin-left: 100px;
      }
    }

    .info-bot {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: start;
      height: 158px;

      .left {
        display: flex;
        align-items: center;
        width: 50%;
        img {
          width: 110px;
        }

        .name-attr-price {
          display: flex;
          flex-direction: column;
          margin-left: 13px;

          .goods-title {
            color: rgba(0, 0, 0, 0.85);
            font-size: 18px;
          }

          .goods-attr-wrap {
            display: flex;
            font-size: 16px;
            margin-top: 16px;
          }

          .goods-price {
            color: rgba(127, 73, 42, 1);
            font-size: 18px;
            margin-top: 16px;
          }
        }
      }

      .right {
        display: flex;
        border-left: 1px solid rgba(0, 0, 0, 0.15);

        .sub-total {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-left: 24px;
          padding-right: 24px;
          width: 50%;
          .fir-itme {
            display: flex;
            flex-direction: column;
            align-items: center;

            .sub-title {
              font-size: 16px;
            }

            .sub-val {
              color: rgba(127, 73, 42, 1);
              font-size: 18px;
              margin-top: 4px;
            }
          }

          .sec-item {
            display: flex;
            flex-direction: column;
            padding: 8px 9px;
            background: rgba(243, 243, 245, 1);
            text-align: center;
            margin-top: 12px;
            font-size: 12px;

            .tax-val {
              margin-top: 4px;
            }
          }
        }

        .operate-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          padding: 0 36px;

          .pay-btn {
            margin-top: 16px;
          }
        }
      }
    }
  }
  .order-table-wrap {
    .order-table-m {
      border-radius: 5px;
      border: 1px solid #ccc;
      margin-top: 10px;
    }

    .table-title {
      border-bottom: 1px solid #ccc;
      // display: flex;
      // justify-content: space-between;
      padding: 20px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
      border-top-left-radius: 5px; /* 左上角圆角 */
      border-top-right-radius: 5px; /* 右上角圆角 */
      .Order-Content {
        display: inline-block;
        font-family: PingFangSC-Regular;
        color: rgba(0, 0, 0, 0.85);
        font-size: 18px;
      }
      .order-content-item {
        div {
          padding: 0 30px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
        }

        div:first-child {
          padding-left: 0;
        }
      }
    }

    .order-table-content {
      // display: flex;
      // justify-content: space-between;

      .order-t-item-wrap {
        // width: 784px;
      }

      .order-table-in {
        display: flex;
        border-top: 1px solid #ccc;
      }

      .order-table-in:nth-child(1) {
        border-top: none;
      }

      .order-table-item {
        width: 700px;
        display: flex;
        padding: 24px 0;
        border-top: 1px solid #ccc;

        .goods-img {
          width: 110px;
          height: 110px;
          background: rgba(243, 243, 245, 1);
          border-radius: 5px;
          margin-left: 24px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 90%;
          }
        }

        .goods-info {
          margin-left: 13px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 2px 0;

          .goods-name {
            color: rgba(0, 0, 0, 0.85);
            font-size: 18px;
            // font-weight: 550;
          }

          .goods-attr {
            display: flex;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;

            div:nth-child(n) {
              margin-left: 49px;
            }

            div:nth-child(1) {
              display: flex;
              align-items: center;
              margin-left: 0;

              .img-wrap {
                width: 40px;
                height: 24px;
                background: rgba(243, 243, 245, 1);

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .goods-price {
            color: rgba(127, 73, 42, 1);
            font-size: 18px;
          }
        }
      }

      .order-table-item:nth-child(1) {
        border-top: none;
      }
      .order-status-item {
        display: flex;
        border-left: 1px solid #ccc;
        justify-content: center;
        width: 184px;
        flex-direction: column;
        text-align: center;
      }
      .order-t-info {
        border-left: 1px solid #ccc;
        display: flex;
        justify-content: center;

        .Refund {
          width: 170px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          border-right: 1px solid #ccc;

          .goods-status {
            padding: 3px 4px;
            border-radius: 2px;
            background-color: rgba(185, 132, 101, 0.15);
            color: rgba(185, 132, 101, 1);
            font-size: 12px;
            margin: 0 auto;
          }

          .detail-btn {
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            margin-top: 17px;
            cursor: pointer;
          }
        }
        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 144px;

          .fir-item {
            display: flex;
            flex-direction: column;
            text-align: center;

            .sub-title {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
            }

            .sub-val {
              color: rgba(127, 73, 42, 1);
              font-size: 18px;
              margin-top: 8px;
            }
          }

          .sec-item {
            margin: 0 auto;
            margin-top: 12px;
            background: rgba(243, 243, 245, 1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: rgba(0, 0, 0, 0.55);
            font-size: 12px;
            text-align: center;
            width: 96px;
            height: 52px;

            .tax-val {
              margin-top: 4px;
            }
          }
        }

        .right {
          width: 170px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          border-left: 1px solid #ccc;

          .goods-status {
            padding: 3px 4px;
            border-radius: 2px;
            background-color: rgba(185, 132, 101, 0.15);
            color: rgba(185, 132, 101, 1);
            font-size: 12px;
            margin: 0 auto;
          }

          .detail-btn {
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            margin-top: 17px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>

