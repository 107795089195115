<template>
  <div class="RefundBuyerSup">
    <Header/>
    <div class="top-space-line"></div>
    <div class="rsh-wrap">
      <div class="left-wrap">
        <div class="step-t-f">
          <span class="proc-sec">1</span>
          <span class="proc-sec-word">{{ 'Buyer Requests A Refund Only' | formatTrans }}</span>
        </div>
        <div class="order-remind">
          <span>{{ 'If you encounter any problems, please contact the seller.' | formatTrans}}</span>
        </div>
        <div class="order-info">
          <div class="title">Refund Item</div>
          <div class="all-goods-wrap">
            <div class="item item-last">
              <div class="ref-goods-img">
                <img
                  src="https://oss.hdy33.com/hdy33/hdy33/2024/7/5/ea373d6e-478c-b02c-8c7a-75c8ba9dcf25KeiskeiArco Floor Lamp@1x.png"
                >
              </div>
              <div class="ref-goods-info">
                <div class="goods-name">Dada Sofa</div>
                <div class="goods-attr">
                  <div>
                    <span>{{ '颜色' | formatTrans }}：</span>
                    <el-tooltip content="red" placement="top">
                      <span class="img-wrap">
                        <img
                          src="https://oss.hdy33.com/hdy33/hdy33/2024/7/5/9685ca31-b870-473a-7b88-accf89fcd5e3编组 16备份 6.png"
                          alt
                        >
                      </span>
                    </el-tooltip>
                  </div>
                  <div>
                    <span>{{ 'Size' | formatTrans }}：</span>
                    <span>0.8m</span>
                  </div>
                  <div>
                    <span>{{ '数量' | formatTrans }}：</span>
                    <span>{{ 21 }}</span>
                  </div>
                </div>
                <div class="goods-price">{{ curSymbol }}2345</div>
              </div>
            </div>
            <div class="more-img">
              <img :src="moreImg">
            </div>
          </div>

          <el-form :model="refSupForm" ref="refSupForm" class="ref-sup-form">
            <el-form-item prop="refType">
              {{ 'Service Type' | formatTrans}}
              <el-select v-model="refSupForm.refType" disabled>
                <el-option
                  v-for="item in refTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="refReason">
              {{ 'Reason For Refund' | formatTrans}}
              <el-select v-model="refSupForm.refReason" placeholder="Select Reason For Refund">
                <el-option
                  v-for="item in refReasList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="refAmount">
              {{ 'Refund Amount' | formatTrans}}
              <el-input v-model="refSupForm.refAmount" clearable placeholder autocomplete="off"></el-input>
              <div
                class="ref-amou-tips"
              >{{ 'Up to $41.50, including shipping postage of $2.00' | formatTrans}}</div>
            </el-form-item>
            <el-form-item prop="refInstr">
              {{ 'Refund Instructions' | formatTrans}}
              <el-input
                v-model="refSupForm.refInstr"
                clearable
                placeholder
                autocomplete="off"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
            <el-form-item>
              {{ 'Upload Images' | formatTrans}}
              <el-upload
                action
                list-type="picture-card"
                :on-preview="hanPicCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt>
              </el-dialog>
              <div class="ref-pic-tips">{{ 'Upload up to 7 images' | formatTrans}}</div>
            </el-form-item>
            <el-checkbox v-model="refSupForm.agreeRem" @change="changeAgreeRem" class="agree-rem">
              <span>{{ 'To use this service, a ' | formatTrans}}</span>
              <span class="agree-cont">{{ '"Return And Exchange Agreement"' | formatTrans}}</span>
              <span>{{ ' must be signed' | formatTrans}}.</span>
            </el-checkbox>
          </el-form>
        </div>

        <div class="submit-btn">
          <span>{{ '提交' | formatTrans}}</span>
        </div>

        <div class="step-sec">
          <div class="step-t-s">
            <span class="proc-sec">2</span>
            <span class="proc-sec-word">{{ 'Seller Processing Refund Request' | formatTrans }}</span>
          </div>
          <div class="re-word-sts">{{ 'Please be patient and wait for processing.' | formatTrans }}</div>
        </div>

        <div class="step-third">
          <div class="step-t-t">
            <span class="proc-third">3</span>
            <span class="proc-third-word">{{ 'Refund Completed' | formatTrans }}</span>
          </div>
          <div class="re-word-sts">Return to the original route.</div>
        </div>
      </div>

      <div class="orderOverview">
        <div class="texc">{{'订单摘要' | formatTrans}}</div>
        <div class="sin-goods-wrap">
          <div class="goods-img">
            <img
              src="http://192.168.1.3:9001/b2c/ua/2024/8/6/998d31e1-3405-df32-23d5-6038093aa9a2Keiskei_Arco Floor Lamp_@1x.png"
            >
          </div>
          <div class="goods-info-wrap">
            <div class="goods-name">{{ '阿萨德困哪是的呢' }}</div>

            <div class="sec-line">
              <div class="goods-price">{{ curSymbol }}{{ 134 }}</div>
              <div class="goods-num">{{'数量' | formatTrans}}: {{ 12 }}</div>
            </div>

            <div class="third-line">
              <span class="sin-guige-wrap">
                <span>Colour:</span>
                <el-tooltip content="绿色" placement="top">
                  <span class="color-img">
                    <img
                      src="http://192.168.1.3:9001/b2c/ua/2024/8/6/998d31e1-3405-df32-23d5-6038093aa9a2Keiskei_Arco Floor Lamp_@1x.png"
                    >
                  </span>
                </el-tooltip>
              </span>
              <span class="sin-guige-wrap">尺寸: 0.6m</span>
            </div>
          </div>
        </div>

        <div class="total-sta">
          <div class="item-tot">
            <div>{{'Amount' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ 3339 }}</div>
          </div>

          <div class="item-tot">
            <div>{{'运费' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ 0 }}</div>
          </div>

          <div class="item-tot">
            <div>{{'Tax' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ 0 }}</div>
          </div>

          <div class="item-tot">
            <div>{{'合计' | formatTrans}}:</div>
            <div class="sub-price">{{ curSymbol }}{{ 3339 }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bot-space-line"></div>
    <Footer/>

    <div class="revoke-remind-wrap" v-show="revokeShow">
      <div class="top-title">
        <img :src="remindGt">
        <span>Revoke</span>
      </div>
      <div class="content">
        <span>After revocation, it cannot be restored. Do you want to perform this operation?</span>
      </div>
      <div class="btn-wrap">
        <div class="cancel-btn">
          <span>cancel</span>
        </div>
        <div class="conf-btn">
          <span>confirm</span>
        </div>
      </div>
    </div>
    <div class="shadow" v-show="revokeShow"></div>
  </div>
</template>
  <script>
import { globalVari } from "@/utils/variable";
import { appCreateOrders, logout } from "@/api/index";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import { getTrans } from "@/api/set.js";
import remindGt from "@/assets/images/remind-gt.png";
import moreImg from "@/assets/images/more-img.png";

export default {
  name: "RefundBuyerSup",
  components: {
    Header,
    Footer
  },

  data() {
    return {
      moreImg,
      remindGt,
      revokeShow: false,
      refTypeList: [
        {
          label: "Refund Only",
          value: 1
        }
      ],
      refReasList: [
        {
          label: "拍错/多拍/不喜欢",
          value: 1
        },
        {
          label: "材质与描述不符",
          value: 2
        },
        {
          label: "颜色/款式/大小/尺寸与描述不符",
          value: 3
        },
        {
          label: "颜色/款式/图案与描述不符",
          value: 4
        },
        {
          label: "退运费",
          value: 5
        },
        {
          label: "卖家发错货",
          value: 6
        },
        {
          label: "假冒品牌",
          value: 7
        },
        {
          label: "收到商品破损、污渍",
          value: 8
        },
        {
          label: "质量问题",
          value: 9
        }
      ],
      dialogVisible: false,
      dialogImageUrl: "",
      refSupForm: {
        refType: 1,
        refReason: "",
        refAmount: 0,
        refInstr: "",
        agreeRem: false
      }
    };
  },

  created() {
    try {
      this.curSymbol = sessionStorage.getItem("currencySymbol");
    } catch (error) {}
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },

  methods: {
    hanPicCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleRemove() {},

    changeAgreeRem(val) {}
  }
};
</script>
<style lang="scss" scoped>
.RefundBuyerSup {
  .top-space-line {
    height: 80px;
  }

  .bot-space-line {
    height: 100px;
  }

  .rsh-wrap {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;

    .left-wrap {
      margin-top: 50px;
      width: 870px;

      .step-t-f {
        color: rgba(0, 0, 0, 0.85);
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;

        .proc-sec {
          width: 26px;
          height: 26px;
          border: 2px solid rgba(0, 0, 0, 0.85);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          font-size: 14px;
          box-sizing: border-box;
        }

        .proc-sec-word {
          margin-left: 12px;
        }
      }

      .order-remind {
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
        margin-top: 8px;
        margin-left: 38px;
      }

      .order-info {
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 25px 24px;
        margin-top: 28px;

        .title {
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
        }

        .all-goods-wrap {
          margin-top: 8px;
          border: 1px solid #eeeeee;
          border-radius: 4px;
          position: relative;

          .item {
            padding: 24px 24px;
            display: flex;
            height: 110px;
            border-bottom: 1px solid #eeeeee;

            .select-img {
              height: 100%;
              display: flex;
              align-items: center;

              img {
                width: 18px;
                height: 18px;
              }
            }

            .ref-goods-img {
              width: 110px;
              height: 110px;
              background: rgba(243, 243, 245, 1);
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .ref-goods-info {
              margin-left: 12px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 2px 0;

              .goods-name {
                font-size: 18px;
                font-weight: bold;
              }

              .goods-attr {
                display: flex;
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;

                div:nth-child(n) {
                  margin-left: 49px;
                }

                div:nth-child(1) {
                  display: flex;
                  align-items: center;
                  margin-left: 0;

                  .img-wrap {
                    width: 40px;
                    height: 24px;
                    background: rgba(243, 243, 245, 1);

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }

              .goods-price {
                color: rgba(127, 73, 42, 1);
                font-size: 18px;
              }
            }
          }

          .item-last {
            border-bottom: none;
          }

          .more-img {
            position: absolute;
            bottom: -33px;
            right: 0;
            width: 54px;
            height: 33px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .submit-btn {
        width: 220px;
        height: 48px;
        border-radius: 29px;
        background: rgba(185, 132, 101, 1);
        text-align: center;
        line-height: 48px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        margin-top: 28px;
      }

      .ref-sup-form {
        margin-top: 32px;

        .el-select,
        .el-input {
          display: block;
          width: 426px;
        }

        .ref-amou-tips,
        .ref-pic-tips {
          color: rgba(0, 0, 0, 0.55);
          font-size: 12px;
        }

        .agree-rem {
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;

          .agree-cont {
            border-bottom: 1px solid rgba(0, 0, 0, 0.85);
          }
        }
      }

      .step-sec {
        border-radius: 10px;
        margin-top: 48px;
        background: #f3f3f5;
        padding: 20px 24px;
        opacity: 0.45;

        .step-t-s {
          font-size: 26px;
          font-weight: bold;
          display: flex;
          align-items: center;

          .proc-sec {
            width: 26px;
            height: 26px;
            border: 2px solid rgba(0, 0, 0, 0.85);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-size: 14px;
            box-sizing: border-box;
          }

          .proc-sec-word {
            margin-left: 12px;
          }
        }

        .re-word-sts {
          margin-left: 40px;
          margin-top: 6px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 12px;
        }
      }

      .step-third {
        border-radius: 10px;
        margin-top: 12px;
        background: #f3f3f5;
        padding: 20px 24px;
        opacity: 0.45;

        .step-t-t {
          font-size: 24px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          align-items: center;

          .proc-third {
            width: 26px;
            height: 26px;
            border: 2px solid rgba(0, 0, 0, 0.85);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-size: 14px;
            box-sizing: border-box;
          }

          .proc-third-word {
            margin-left: 12px;
          }
        }

        .re-word-sts {
          color: rgba(0, 0, 0, 0.85);
          font-size: 12px;
          margin-top: 4px;
          margin-left: 40px;
        }
      }
    }

    .orderOverview {
      width: 300px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      margin-top: 54px;

      .texc {
        margin-top: 24px;
      }

      .sin-goods-wrap {
        width: 252px;
        margin: 0 auto;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding: 24px 0 20px 0;
        display: flex;

        .goods-img {
          width: 70px;
          height: 70px;
          background: rgba(243, 243, 245, 1);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 80%;
          }
        }

        .goods-info-wrap {
          width: 170px;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .goods-name {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-weight: 550;
          }

          .sec-line {
            margin-top: 4px;
            display: flex;
            justify-content: space-between;

            .goods-price {
              color: rgba(127, 73, 42, 1);
              font-size: 14px;
            }

            .goods-num {
              color: rgba(0, 0, 0, 0.85);
              font-size: 12px;
            }
          }

          .third-line {
            display: flex;
            justify-content: space-between;
            font-size: 12px;

            .sin-guige-wrap {
              display: flex;
              align-items: center;

              .color-img {
                width: 33px;
                height: 20px;
                background: rgba(243, 243, 245, 1);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 4px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .total-sta {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        padding: 20px 0 20px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        width: 252px;
        margin: 0 auto;

        .item-tot {
          margin-top: 12px;
          display: flex;
          justify-content: space-between;
        }

        .item-tot:nth-child(1) {
          margin-top: 0;
        }

        .sub-price {
          color: rgba(127, 73, 42, 1);
          font-weight: bold;
        }
      }
    }
  }

  .revoke-remind-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 480px;
    padding: 32px 32px 24px 34px;
    z-index: 100001;

    .top-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }

      span {
        margin-left: 16px;
      }
    }

    .content {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      margin-top: 10px;
    }

    .btn-wrap {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancel-btn {
        width: 126px;
        height: 38px;
        border-radius: 29px;
        border: 1px solid rgba(185, 132, 101, 1);
        color: rgba(185, 132, 101, 1);
        font-size: 16px;
        text-align: center;
        line-height: 38px;
        cursor: pointer;
      }

      .conf-btn {
        width: 126px;
        height: 38px;
        border-radius: 29px;
        background: rgba(185, 132, 101, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 38px;
        text-align: center;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .shadow {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
    opacity: 0.55;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100000;
  }
}
</style>
    
    